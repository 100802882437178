<template>
    <el-main class="room_details" v-loading="loading">
        <div class="top_banner">
            <div class="navigation_bar">
                <div>
                    <img src="../assets/img/logo_mz2.png" alt="" style="width:302px;height:34px;">
                    <div style="display: flex;align-items: center;">
                        <el-input
                            placeholder="请输入区域、小区名"
                            prefix-icon="el-icon-search"
                            v-model="search"
                            @keyup.enter.native="search_gjz"
                            style="width:200px;">
                        </el-input>
                        <ul>
                            <!-- <li v-for="(item,index) in nav_top" :key="index">
                                <router-link :to="{path:item.url}" :class="nav_top_active==index?'nav_active':''" style="text-decoration: none;outline: none;">{{item.name}}</router-link>
                            </li> -->
                            <li v-for="(item,index) in nav_top" :key="index">
                                <router-link v-if="item.name!=='发布'" :to="{path:item.url}" :class="nav_top_active==index?'nav_active':''" style="text-decoration: none;outline: none;">{{item.name}}</router-link>
                                <div v-else :class="nav_top_active==index?'nav_active':''" @click="rich_text_show" class="fbfy fbfy_after" style="text-decoration: none;outline: none;">{{item.name}}</div>
                            </li>
                            <li class="login_css">
                                <img src="../assets/img/tx@2x_black.png" alt="" style="width:22px;height:22px;">
                                <span v-if="!username" @click="login_show">登录/注册</span>
                                <div v-else>
                                    <span style="margin-right:10px;">{{username}}</span>
                                    <span @click="exit">退出</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="subject">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
                <el-breadcrumb-item>优惠房源</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="room_introduce">
                <div class="room_introduce_img">
                <!-- <div class="room_introduce_img" v-if="details_data.lubo.length>0"> -->
                    <div>
                        <video v-if="details_data.lubo[swiper_active].type==2" class="room_img" :src="details_data.lubo[swiper_active].file" controls></video>
                        <img v-else class="room_img" :src="details_data.lubo[swiper_active].file" alt="">
                        <div class="room_btns">
                            <el-radio-group v-model="room_img_active" size="small" @change="change_room_img">
                                <el-radio-button label="视频"></el-radio-button>
                                <el-radio-button label="图片"></el-radio-button>
                            </el-radio-group>
                        </div>
                        <!-- <img v-show="details_data.lubo[swiper_active].type==2" class="bf_img" src="../assets/img/bf@2x.png" alt=""> 
                         poster="https://image.manzu365.com/uploads/image/20220411023956_44074.png"-->
                    </div>
                    <div>
                        <div class="swiper mySwiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" :class="swiper_active==index?'swiper_active_css':''" v-for="(item,index) in details_data.lubo" :key="index" @click="active_room_img(index)">
                                    <video v-if="item.type==2" :src="item.file" style="border-radius: 2px;"></video>
                                    <img v-else :src="item.file" alt="" style="border-radius: 2px;">
                                </div>
                            </div>
                            <div class="swiper-button-next swiper-button-white"></div>
                            <div class="swiper-button-prev swiper-button-white"></div>

                            <div class="swiper-pagination"></div>
                        </div>

                    </div>
                </div>
                <!-- <el-empty style="width: 732px;" description="暂无图片" v-else></el-empty> -->
                <div class="room_introduce_detail">
                    <span style="font-size: 26px;font-weight: 600;color: #333333;margin-bottom:16px;">{{details_data.room_right.title}}</span>
                    <span style="font-size: 14px;color: #666666;">{{details_data.room_right.address}}</span>
                    <!-- <div class="item_tags">
                        <span v-for="(item_ts,index_ts) in 10" :key="index_ts">外阳台</span>
                    </div> -->
                    <div>
                        <span style="font-size:28px;color:#FF5717;">{{details_data.room_right.price}}</span>
                        <span style="font-size:14px;color:#FF5717;">元/月</span>
                    </div>
                    <div class="room_information">
                        <div>
                            <span>{{details_data.room_right.czfs}}</span>
                            <span>出租方式</span>
                        </div>
                        <div>
                            <span>{{details_data.room_right.fx}}</span>
                            <span>户型</span>
                        </div>
                        <div>
                            <span>{{details_data.room_right.mj}}m²</span>
                            <span>面积</span>
                        </div>
                    </div>
                    <div class="gj_css">
                        <el-avatar :size="56" :src="details_data.room_right.face"></el-avatar>
                        <div>
                            <div style="font-size: 18px;color: #333333;">{{details_data.room_right.name}}</div>
                            <div style="font-size: 14px;color: #999999;">
                                <span>{{details_data.room_right.md}} {{details_data.room_right.zw}}</span>
                            </div>
                        </div>
                    </div>
                    <div @click="dhzx" class="introduce_btns">电话咨询</div>
                    <!-- <div @click="subscribe_show" class="introduce_btns" style="background: #FFD542;border-color:#FFD542;margin-bottom:30px;">预约看房</div> -->
                    <!-- <div class="code_css">
                        <div style="width: 62px;height: 60px;background:#99989B;margin-right:10px;"></div>
                        <div>
                            <div style="margin-bottom:10px;color: #333333;font-size: 14px;">优悠租房小程序二维码</div>
                            <div style="color: #333333;font-size: 14px;">扫一扫轻松优惠租好房</div>
                        </div>
                    </div> -->
                    

                </div>
            </div>

            <div class="navigation_bar" id="boxFixed" :class="{'is_fixed' : isFixed}" style="background: #F2F2F2;margin-bottom:50px;box-shadow: none;">
                <div>
                    <ul>
                        <li v-for="(item,index) in nav_middle" :key="index">
                            <span @click="quick_navigation(item.ref,index)" style="font-size:18px;margin-right:70px;font-weight: 600;cursor: pointer;">{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="navigation_bar_title" ref="fyxx">房源信息</div>
            <div class="item_tags">
                <span v-for="(item_ts,index_ts) in details_data.room_right.tag" :key="index_ts">{{item_ts}}</span>
            </div>
            <div class="jbxx_css">
                <div>基本信息</div>
                <div>
                   <span v-for="(item,index) in details_data.room_info" :key="index">{{item.name}}{{item.value}}</span>
                </div>
            </div>
            <div class="ptss_css">
                <div>配套设施</div>
                <div>
                    <div v-for="(item,index) in details_data.pzss" :key="index">
                        <img :src="item.icon" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>


            <!-- <div class="navigation_bar_title" ref="fyms">房源描述</div> -->
            <div class="fyms_css">
                <el-avatar :size="56" :src="details_data.ld.log"></el-avatar>
                <div>
                    <span>{{details_data.ld.name}}</span>
                    <span>{{details_data.ld.address}}</span>
                </div>
            </div>
            <!-- <div class="describe">{{details_data.ld.desc}}</div> -->


            <div class="navigation_bar_title" ref="zbpt">周边配套</div>
            <div style="height: 480px;margin-bottom: 50px;" class="room_map">
                <div class="map_popup">
                    <div>
                        <div v-for="(item,index) in map_popup_arr" :key="index" @click="switch_position(index);" :class="nearMode==index?'active_popup':''">
                            <img v-show="nearMode!=index" :src="item.url" alt="">
                            <img v-show="nearMode==index" :src="item.url_active" alt="">
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                    <div>
                        <div v-for="(item,index) in nearList[nearMode]" :key="index">
                            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                                <span>{{item.title}}</span>
                                <span v-if="!!item.distance">{{item.distance}}m</span>
                            </div>
                            <span style="color:#999;">{{item.value}}</span>
                        </div>
                    </div>
                </div>
                <baidu-map id="map" @ready="mapReady" style="width: 100%;height: 100%;"></baidu-map>
                <!-- <baidu-map id="map" style="width: 100%;height: 100%;"></baidu-map> -->
            </div>

            <div class="navigation_bar_title" ref="xsfy">相似房源</div>
            <div class="room_card_list">
                <div v-for="(item,index) in details_data.similarFy" :key="index" :style="(index+1)%3!=0?'margin-right: 25px;':''" class="room_card_item">
                    <router-link :to="{path:'/room_details',query:{id:item.id}}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                        <div class="item_img">
                            <img :src="item.coverImage" alt="">
                        </div>
                        <div>
                            <span>{{item.title}}</span>
                            <span>{{item.stationTitle}}</span>
                            <div class="item_tags">
                                <span v-for="(item_ts,index_ts) in item.roomTags" :style="index_ts>=3?'display: none;':''" :key="index_ts">{{item_ts}}</span>
                            </div>
                            <span>¥{{item.roomPrice}}元/月</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <div v-if="details_data.nearFy.lenght>0" class="navigation_bar_title" ref="fjfy">附近房源</div>
            <div class="room_card_list">
                <div v-for="(item,index) in details_data.nearFy" :key="index" :style="(index+1)%3!=0?'margin-right: 25px;':''" class="room_card_item">
                    <router-link :to="{path:'/room_details',query:{id:item.id}}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                        <div class="item_img">
                            <img :src="item.coverImage" alt="">
                        </div>
                        <div>
                            <span>{{item.title}}</span>
                            <span>{{item.stationTitle}}</span>
                            <div class="item_tags">
                                <span v-for="(item_ts,index_ts) in item.roomTags" :style="index_ts>=3?'display: none;':''" :key="index_ts">{{item_ts}}</span>
                            </div>
                            <span>¥{{item.roomPrice}}元/月</span>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

        <company-information></company-information>

        <el-dialog
        title="预约看房"
        custom-class="subscribe"
        :visible.sync="subscribe_dialog"
        width="500px"
        top="20vh"
        :close-on-click-modal="false"
        :before-close="subscribe_handleClose">
            <el-form label-position="right" label-width="90px" class="subscribe_stylt">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item>
                            <span slot="label">手机号码：</span>
                            <el-input v-model="yykf_data.tel" placeholder="请输入手机号码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <span slot="label">看房日期：</span>
                            <el-date-picker
                                v-model="yykf_data.date"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <span slot="label">看房时间：</span>
                            <div class="kfsj">
                                <span v-for="(item,index) in kfsj_arr" :key="index" @click="yykf_data.kfsj=index" :class="yykf_data.kfsj==index?'active_kfsj':''">{{item.name}}</span>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="subscribe_handleClose" class="btn_close">取 消</el-button>
                <el-button @click="subscribe_submit" type="primary" class="btn_submit">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog
        :title="dialog_title"
        custom-class="login register"
        :visible.sync="login_dialog"
        width="520px"
        top="20vh"
        :close-on-click-modal="false"
        :before-close="lr_close">
            <div v-show="dialog_title=='登录'">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/sjh@2x.png" alt="">
                            <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/mm@2x.png" alt="">
                            <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div v-show="dialog_title=='注册'" class="register_input">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                    </el-col>
                    <el-col :span="24" class="yzm">
                        <el-input v-model="code" placeholder="请输入验证码"></el-input>
                        <el-button @click="obtain_code" type="primary" :disabled="code_disabled">{{code_text}}</el-button>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="repassword" placeholder="请再次输入密码" type="password"></el-input>
                    </el-col>
                </el-row>
                <el-checkbox v-model="agreement_val">
                    <router-link :to="{path:'/agreement'}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                    我接受用户协议和隐私政策
                    </router-link>
                </el-checkbox>
            </div>

            <span v-show="dialog_title=='登录'" slot="footer" class="dialog-footer">
                <el-button @click="login_submit" type="primary" class="btn_submit">登 录</el-button>
                <el-button @click="login_handleClose" class="btn_close">注 册</el-button>
            </span>

            <span v-show="dialog_title=='注册'" slot="footer" class="dialog-footer">
                <el-button @click="register_submit" :disabled="!agreement_val" type="primary" class="btn_submit">注 册</el-button>
                <el-button @click="register_handleClose" class="btn_close">返回登录</el-button>
            </span>
        </el-dialog>



        <el-dialog
        title="发布房源"
        custom-class="rich_text"
        :visible.sync="rich_text_dialog"
        width="1000px"
        top="5vh"
        :close-on-click-modal="false"
        :before-close="rich_text_handleClose">

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"> 
                <el-form-item label="房源标题：" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
                <!-- 富文本编辑器 -->
                <el-form-item label="房源内容：" prop="content">
                    <!-- 失去焦点时手动校验该字段 (当前 content 属性值) -->
                    <el-upload class="avatar-uploader" :action="uploadUrl" name="file" :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeUpload" :multiple="true" style="display:none;"></el-upload>
                        <quill-editor
                            ref="QuillEditor"
                            :options="editorOption"
                            @blur="$refs.ruleForm.validateField('content')"
                            v-model="ruleForm.content"/>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="rich_text_handleClose" class="btn_close">取 消</el-button>
                <el-button @click="rich_text_submit" type="primary" class="btn_submit">发 布</el-button>
            </span>
        </el-dialog>


    </el-main>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import {distance, handleSort} from "../../utils/tools";
import {bcadd} from "../../utils/bc";
import { post,getToken ,setToken } from "@/api/request";
import companyInformation from '../components/companyInformation.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // 加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'],                      //引用，代码块
  [{ 'header': 1 }, { 'header': 2 }],               // 几级标题
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],     // 有序列表，无序列表
  [{ 'script': 'sub' }, { 'script': 'super' }],      // 下角标，上角标
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // 缩进
  [{ 'direction': 'rtl' }],                         // 文字输入方向
  [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],// 标题
  [{ 'color': [] }, { 'background': [] }],          // 颜色选择
  [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],// 字体
  [{ 'align': [] }], // 居中
  ['clean'],                                       // 清除样式,
  ['link', 'image'],  // 上传图片、上传视频
]

export default {
    name:'room_details',
    components:{
        companyInformation,
    },
    data(){
        return{
            id:'',
            loading:false,

            search:'',

            nav_top_active:0,
            nav_top:[
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],


            nav_middle_active:0,
            nav_middle:[
                {name:'房源信息',ref:'fyxx'},
                {name:'房源描述',ref:'fyms'},
                {name:'周边配套',ref:'zbpt'},
                {name:'相似房源',ref:'xsfy'},
                {name:'附近房源',ref:'fjfy'},
            ],

            room_img_active:'视频',

            swiper_active:0,

            details_data:{
                "lubo": [
                    {
                        "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                        "type": 1
                    },
                    {
                        "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                        "type": 1
                    },
                    {
                        "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                        "type": 1
                    },
                    {
                        "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                        "type": 1
                    },
                    {
                        "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                        "type": 1
                    }
                ],
                "lng": "113.33631400",
                "lat": "23.18702900",
                "room_right": {
                    "title": "百事可乐公寓·A栋 2室1厅1卫 A305",
                    "address": "白云区·3号线北延段\t-京溪南方医院584米",
                    "tag": [
                        "外阳台"
                    ],
                    "price": "0.00",
                    "fkfs": "押一付一",
                    "czfs": "",
                    "fx": "2室1厅1卫",
                    "mj": "0.00",
                    "tel": "18866668888"
                },
                "room_info": [
                    {
                        "name": "入住：",
                        "value": "随时入住"
                    },
                    {
                        "name": "车位：",
                        "value": "无车位"
                    },
                    {
                        "name": "楼层：",
                        "value": "5/"
                    },
                    {
                        "name": "电梯：",
                        "value": "无电梯"
                    },
                    {
                        "name": "装修：",
                        "value": "精装修"
                    },
                    {
                        "name": "朝向：",
                        "value": "东西"
                    },
                    {
                        "name": "适合：",
                        "value": "适合养宠物"
                    }
                ],
                "pzss": [
                    {
                        "name": "空调",
                        "icon": "https://assets.yoyozufang.com/appimg/40EA92BBAD142D53586B62100CE6A56E.png"
                    },
                    {
                        "name": "衣柜",
                        "icon": "https://assets.yoyozufang.com/appimg/7823E3F81737DD5CA6D8C4613881438A.png"
                    },
                    {
                        "name": "床铺",
                        "icon": "https://assets.yoyozufang.com/appimg/14B4701939D870E28E4A1B9AA5A1F547.png"
                    },
                    {
                        "name": "椅子",
                        "icon": "https://assets.yoyozufang.com/appimg/F2E3C3046D11410A231830E14DA4F952.png"
                    }
                ],
                "ld": {
                    "name": "百事可乐公寓·A栋",
                    "log": null,
                    "address": "白云区 京溪",
                    "desc": "嘤嘤嘤"
                },
                "similarFy": [
                    {
                        "id": 30085,
                        "title": "丘壑公寓·A栋 2室1厅1卫 A509",
                        "stationTitle": "白云区·3号线北延段\t-白云大道北606米",
                        "roomTags": [],
                        "roomPrice": "0.00",
                        "coverImage": null
                    },
                    {
                        "id": 30075,
                        "title": "丘壑公寓·A栋 2室1厅1卫 A409",
                        "stationTitle": "白云区·3号线北延段\t-白云大道北606米",
                        "roomTags": [],
                        "roomPrice": "0.00",
                        "coverImage": null
                    },
                    {
                        "id": 30066,
                        "title": "丘壑公寓·A栋 2室1厅1卫 A309",
                        "stationTitle": "白云区·3号线北延段\t-白云大道北606米",
                        "roomTags": [],
                        "roomPrice": "0.00",
                        "coverImage": null
                    }
                ],
                "nearFy": [
                    {
                        "id": 30156,
                        "title": "百事可乐公寓·A栋 1室1厅1卫 A304",
                        "stationTitle": "白云区·3号线北延段\t-京溪南方医院584米",
                        "roomTags": [],
                        "roomPrice": "1300.00",
                        "coverImage": null
                    },
                    {
                        "id": 30155,
                        "title": "百事可乐公寓·A栋 1室1厅1卫 A303",
                        "stationTitle": "白云区·3号线北延段\t-京溪南方医院584米",
                        "roomTags": [],
                        "roomPrice": "1300.00",
                        "coverImage": null
                    },
                    {
                        "id": 30154,
                        "title": "百事可乐公寓·A栋 单间 A302",
                        "stationTitle": "白云区·3号线北延段\t-京溪南方医院584米",
                        "roomTags": [],
                        "roomPrice": "1000.00",
                        "coverImage": null
                    }
                ]
            },

            nearList: [],
            nearMode: 0,

            map_popup_arr:[
                {
                    name:'地铁',
                    url:require('../assets/img/dt_1@2x.png'),
                    url_active:require('../assets/img/dt_2@2x.png'),
                },
                {
                    name:'公交',
                    url:require('../assets/img/gj_1@2x.png'),
                    url_active:require('../assets/img/gj_2@2x.png'),
                },
                {
                    name:'学校',
                    url:require('../assets/img/xx_1@2x.png'),
                    url_active:require('../assets/img/xx_2@2x.png'),
                },
                {
                    name:'医院',
                    url:require('../assets/img/yy_1@2x.png'),
                    url_active:require('../assets/img/yy_2@2x.png'),
                },
                {
                    name:'银行',
                    url:require('../assets/img/yh_1@2x.png'),
                    url_active:require('../assets/img/yh_2@2x.png'),
                },
                {
                    name:'餐饮',
                    url:require('../assets/img/cy_1@2x.png'),
                    url_active:require('../assets/img/cy_2@2x.png'),
                },
                {
                    name:'购物',
                    url:require('../assets/img/gw_1@2x.png'),
                    url_active:require('../assets/img/gw_2@2x.png'),
                },
            ],

            periphery_data:[],

            isFixed: false,
            offsetTop:0,

            subscribe_dialog:false,
            yykf_data:{
                tel:'',
                date:'',
                kfsj:0
            },
            kfsj_arr:[
                {
                    name:'全天',
                    val:0,
                },{
                    name:'上午',
                    val:1,
                },{
                    name:'下午',
                    val:2,
                },{
                    name:'晚上',
                    val:3,
                }
            ],


            username:'',
            dialog_title:'登录',
            login_dialog:false,
            tel:'',
            code:'',
            passwrod:'',
            repassword:'',

            code_disabled:false,
            code_text:'获取验证码',


            rich_text_dialog:false,
            ruleForm: {
                content: '', 
                title: '', 
            },
            rules: {
                content: [
                    { required: true, message: '请输入文本', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ],
            },


            editorOption: {
                placeholder: '请在这里输入',
                theme: 'snow', //主题 snow/bubble
                modules: {
                history: {
                    delay: 1000,
                    maxStack: 50,
                    userOnly: false
                },
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                    image: function (value) {
                        if (value) {
                        // 调用element的图片上传组件
                        document.querySelector('.avatar-uploader input').click()
                        } else {
                        this.quill.format('image', false)
                        }
                    }
                    }
                }
                }
            },

            uploadUrl:'https://wxapp.manzu365.com/api/com/upload',



            agreement_val:false,


        }
    },
    created(){
        this.id = this.$route.query.id;
        this.get_data();
        if(getToken()){
            this.username = localStorage.getItem("username");
        }

    },
    methods:{
        search_gjz(){
            post('index/icp/search', {key:this.search}).then(res => {
                if (res.err == 0) {
                    this.$router.push({path: '/search'});
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        beforeUpload (file) { },
        uploadSuccess (res) {
            // 获取富文本组件实例
            let quill = this.$refs.QuillEditor.quill
            // 如果上传成功
            if (res.err === 0 && res.data.url) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', res.data.url)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败！')
            }
        },

        get_data(){
            this.loading = true;
            post('index/icp/info', {id:this.id}).then(res => {
                if (res.err == 0) {
                    this.details_data = res.data;
                    if(this.details_data.lubo.length==0){
                        this.details_data.lubo.push({
                            "file": "https://assets.yoyozufang.com/appimg2/20220323/ihS27SMvg7VE.png",
                            "type": 1
                        },)
                    }
                }
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        // 发布房源
        rich_text_show(){
            let token = getToken();
            if(!token){
                this.$message.error('请先登录!');
                return;
            }
            this.rich_text_dialog = true;
        },
        rich_text_handleClose(){
            this.rich_text_dialog = false;
            this.$refs.ruleForm.resetFields();
        },
        rich_text_submit(){
            let token = getToken();
            post('index/icp/fb', {
                ...this.ruleForm,
                token:token
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发布成功!',
                        type: 'success'
                    });
                    this.rich_text_dialog = false;
                    this.$refs.ruleForm.resetFields();
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        
        login_show(){
            this.login_dialog = true;
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
        },
        login_handleClose(){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '注册';
        },
        login_submit(){
            this.loading = true;
            post('index/icp/login', {
                tel:this.tel,
                passwrod:this.passwrod
            }).then(res => {
                if (res.err == 0) {
                    this.username = res.data.username;
                    setToken(res.data.token);
                    localStorage.setItem('username', res.data.username);
                    this.login_dialog = false;
                }
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        register_handleClose(){
            this.dialog_title = '登录';
        },
        obtain_code(){
            post('index/icp/sendcode', {
                tel:this.tel,
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发送成功!',
                        type: 'success'
                    });
                    this.code_disabled = true;
                    let num = 60;
                    this.timer = setInterval(()=>{
                        num--;
                        if(num > 0){
                            this.code_text = num+'秒';
                            this.$apply();
                        }else{
                            clearInterval(this.timer); //清除js定时器
                            this.code_text = '发送验证码';
                            this.code_disabled = false;
                            this.$apply();
                        }
                    },1000)
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        register_submit(){
            post('index/icp/reg', {
                tel:this.tel,
                code:this.code,
                passwrod:this.passwrod,
                repassword:this.repassword,
            }).then(res => {
                if (res.err == 0) {
                    this.tel = '';
                    this.code = '';
                    this.passwrod = '';
                    this.repassword = '';
                    this.dialog_title = '登录';
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        lr_close(done){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '登录';
            done();
        },
        exit(){
            this.nav_top = [
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],
            this.username = '';
            window.localStorage.clear();
            // localstore.removeSession(youyou_token);
            window.location.reload();
        },



        quick_navigation(ref,index){
            this.$refs[ref].scrollIntoView();
            this.nav_middle_active=index;
        },
        active_room_img(index){
            if(this.swiper_active == index){
                return;
            }
            this.swiper_active = index;
        },
        change_room_img(){
            let type = 1;
            if(this.room_img_active=='视频'){
                type = 2;
            }
            this.swiper_active = this.details_data.lubo.findIndex(item => item.type==type)
        },



        mapReady({BMap, map}) {
            this.Map = map
            this.BMap = BMap
            const point = new this.BMap.Point(this.details_data.lng, this.details_data.lat);
            map.centerAndZoom(point, 15);
            const markerGy = new BMap.Icon('./icon/map_marker_gy.png', new BMap.Size(46, 46))
            markerGy.setImageSize(new BMap.Size(28,30))
            map.addOverlay(new BMap.Marker(point, {icon: markerGy}))
            this.location = new BMap.LocalSearch(map, {
                onSearchComplete: function (res) {
                    console.log(res,"周边");
                    const near = [[],[],[],[],[],[],[]]
                    if (this.location.getStatus() === 0) {
                        for (let i = 0; i < res.length; i++) {
                            let data = [];
                            if(res[i].keyword=='地铁站'){
                                for (let j = 0; j < res[i].getCurrentNumPois(); j++) {
                                    if (!!res[i].getPoi(j).address&&!!res[i].getPoi(j).point&&res[i].getPoi(j).type==3) {
                                        let item = {
                                            title: res[i].getPoi(j).title,
                                            value: res[i].getPoi(j).address,
                                            point: res[i].getPoi(j).point,
                                            distance: res[i].getPoi(j).point ? bcadd(distance({lng:this.details_data.lng, lat:this.details_data.lat}, res[i].getPoi(j).point, false), 0, 0) : null
                                        }
                                        data.push(item)
                                    }
                                }
                            } else if(res[i].keyword=='公交站'){
                                for (let j = 0; j < res[i].getCurrentNumPois(); j++) {
                                    if (!!res[i].getPoi(j).address&&!!res[i].getPoi(j).point&&res[i].getPoi(j).type==1) {
                                        let item = {
                                            title: res[i].getPoi(j).title,
                                            value: res[i].getPoi(j).address,
                                            point: res[i].getPoi(j).point,
                                            distance: res[i].getPoi(j).point ? bcadd(distance({lng:this.details_data.lng, lat:this.details_data.lat}, res[i].getPoi(j).point, false), 0, 0) : null
                                        }
                                        data.push(item)
                                    }
                                }
                            } else {
                                for (let j = 0; j < res[i].getCurrentNumPois(); j++) {
                                    if (!!res[i].getPoi(j).address) {
                                        let item = {
                                            title: res[i].getPoi(j).title,
                                            value: res[i].getPoi(j).address,
                                            point: res[i].getPoi(j).point,
                                            distance: res[i].getPoi(j).point ? bcadd(distance({lng:this.details_data.lng, lat:this.details_data.lat}, res[i].getPoi(j).point, false), 0, 0) : null
                                        }
                                        data.push(item)
                                    }
                                }
                            }
                            data.sort(handleSort('distance'))
                            near[i] = data
                        }
                    }
                    this.nearList = near
                    // 设置地图标点
                    this.setMapMarker()
                }.bind(this)
            })
            this.location.searchNearby(['地铁站', '公交站', '学校', '医院' ,'银行' ,'餐饮' ,'购物'], point, 5000)
        },
        setMapGyMark: function () {
            const icon = new this.BMap.Icon('./icon/map_marker_gy.png', new this.BMap.Size(46, 46))
            icon.setImageSize(new this.BMap.Size(28,30))
            const point = new this.BMap.Point(this.details_data.lng, this.details_data.lat)
            this.Map.addOverlay(new this.BMap.Marker(point, {icon:icon}))
            this.Map.centerAndZoom(point, 15)
        },
        setMapMarker() {
            this.traffic_list = []
            // 移除所有图标
            this.Map.clearOverlays()
            // // 添加公寓图标
            this.setMapGyMark()
            // 添加其他图标
            const data = this.nearList[this.nearMode];
            // console.log(data,'data');
            // for (let i = 0; i < data.length; i++) {
            //     if (data[i].point) {
            //         // let icon = new this.BMap.Icon('./icon/map_marker_'+ this.nearMode +'.png', new this.BMap.Size(46, 46))
            //         // icon.setImageSize(new this.BMap.Size(28,30))
            //         // const marker = new this.BMap.Marker(new this.BMap.Point(data[i].point.lng, data[i].point.lat), {icon: icon})
            //         // this.Map.addOverlay(marker)
            //     } else {
            //         console.log('坐标信息不存在：' + data[i].point)
            //     }
            // }
        },
        handleLine() {
            this.load = true
            const geo = new this.BMap.Geocoder()
            geo.getPoint(this.s_address, (point) => {
                if (point) {
                    this.handleTransit([this.details_data.lng, this.details_data.lat], point)
                } else {
                    console.log('目的地不明确')
                }
            })
        },
        handleTransit(s_point, e_point) {
            const transit = new this.BMap.TransitRoute(this.Map, {
                renderOptions: {map: this.Map, panel: 'result'},
                onSearchComplete: function (res) {
                    if (res.Hr.length) {
                        this.traffic_list = res.Hr
                    } else {
                        this.$message.warning('请精确目的地信息')
                    }
                    this.load = false
                }.bind(this)
            })
            const start = new this.BMap.Point(s_point.lng, s_point.lat)
            const end = new this.BMap.Point(e_point.lng, e_point.lat)
            transit.setPolicy(0);
            transit.search(start, end)
        },

        switch_position(index){
            this.nearMode=index;
            this.setMapMarker();
        },

        initHeight () {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            this.isFixed = scrollTop > this.offsetTop ? true : false;
        },

        subscribe_show(){
            this.subscribe_dialog = true;
            this.yykf_data.tel = '';
            this.yykf_data.date = '';
            this.yykf_data.kfsj = 0;
        },
        subscribe_handleClose(){
            this.subscribe_dialog = false;
        },
        subscribe_submit(){
            this.subscribe_dialog = false;
        },


        // to_xs(id){
        //     this.$router.push({path:'/room_details',query:{id:id}});
        // },
        // to_fj(id){
        //     this.$router.push({path:'/room_details',query:{id:id}});
        // },

        dhzx(){
            this.$alert(this.details_data.room_right.tel, '咨询电话', {
                confirmButtonText: '关闭',
                callback: action => {}
            });
        },


    },
    mounted(){
        let swiper = new Swiper(".mySwiper", {
            slidesPerView: 5,
            spaceBetween: 10,
            // pagination: {
            //     el: ".swiper-pagination",
            //     clickable: true,
            // },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            preventClicksPropagation : true,
        });

        window.addEventListener('scroll',this.initHeight,true);
        this.$nextTick( () => {
            this.offsetTop = document.querySelector('#boxFixed').offsetTop;
        })
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    watch: {
        'details_data.lng'(){
           this.mapReady({BMap:this.BMap, map:this.Map});
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep.room_details{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .top_banner{
        width: 100%;
        height: 64px;
        margin-bottom: 40px;
    }
    .navigation_bar{
        background-color: #fff;
        display: flex;
        justify-content: center;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
        z-index: 99;
        >div{
            width: 1156px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-input__inner{
                height: 32px;
                line-height: 32px;
                border-radius: 16px;
                background: #F0F0F0;
            }
            .el-input__icon{
                line-height: 32px;
            }
            ul{
                display: flex;
                li{ 
                    padding:0;
                    margin:0;
                    list-style:none;
                    a{
                        margin-right: 20px;
                        font-size: 16px;
                        padding: 9px 0;
                        color: #666;
                        position: relative;
                    }
                    .fbfy{
                        margin-right: 20px;
                        font-size: 16px;
                        color: #666;
                        position: relative;
                        cursor: pointer;
                    }
                    a:hover:after,
                    .fbfy:hover:after {
                        width: 120%;
                    }
                    a:after,
                    .fbfy:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        width: 0;
                        height: 2px;
                        background: #FFD542;
                        border-radius: 1px;
                        -webkit-transition: width .3s;
                        transition: width .3s;
                        will-change: width;
                    }
                    .nav_active{
                        position: relative;
                        color: #333333
                    }
                    .nav_active:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        width: 100%;
                        height: 2px;
                        background: #FFD542;
                        border-radius: 1px;
                    }
                    .fbfy_after:after{
                        bottom: -9px;
                    }
                }
            }
        }
        .login_css{
            display: flex;
            color: #666;
            img{
                margin-right: 8px;
            }
            span{
                cursor: pointer;
            }
        }
    }
    .is_fixed{
        position: fixed;
        top: 0;
        left: 50%;
        margin-left: -586px;
        z-index: 999;
    }

    .subject{
        width: 1156px;
        .room_introduce{
            margin-top: 30px;
            margin-bottom: 40px;
            display: flex;
            .room_introduce_img{
                display: flex;
                flex-direction: column;
                >div:nth-child(1){
                    width: 732px;
                    height: 548px;
                    border-radius: 2px;
                    position: relative;
                    overflow: hidden;
                    .room_img{
                        width: 100%;
                        height: 100%;
                    }
                    .room_btns{
                        position: absolute;
                        bottom: 30px;
                        left: 50%;
                        margin-left: -60px;
                        .el-radio-button__inner{
                            padding: 9px 17px;
                            font-size: 14px;
                            color: #999999;
                        }
                        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                            color: #333333;
                            background-color: #FFD542;
                            border-color: #FFD542;
                            box-shadow: -1px 0 0 0 #FFD542;
                        }
                        .el-radio-button__inner:hover {
                            color: #FFD542;
                        }
                    }
                    .bf_img{
                        position: absolute;
                        width: 66px;
                        height: 66px;
                        top: 50%;
                        left: 50%;
                        margin-top: -33px;
                        margin-left: -33px;
                    }
                }
                >div:nth-child(2){
                    margin-top: 10px;
                    position: relative;
                    overflow: hidden;
                    height: 90px;
                    width: 732px;
                    .swiper-button-prev{
                        background-image:url("../assets/img/zuohua@2x.png");
                        background-size: 32px 32px;
                    }
                    .swiper-button-next{
                        background-image:url("../assets/img/youhua@2x.png");
                        background-size: 32px 32px;
                    }
                }
            }
            .room_introduce_detail{
                flex: 1;
                height: 649px;
                // max-height: 649px;
                // overflow-y: auto;
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                box-sizing: border-box;
                padding: 20px;
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                

                .room_information{
                    border-top: 1px solid #F2F2F2;
                    border-bottom: 1px solid #F2F2F2;
                    padding: 20px 0;
                    margin: 25px 0;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    >div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        >span:nth-child(1){
                            font-size: 18px;
                            color: #333333;
                        }
                        >span:nth-child(2){
                            font-size: 14px;
                            color: #ABABAB;
                        }
                    }
                }

                .gj_css{
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    >div{
                        margin-left: 15px;
                    }
                }

                .introduce_btns{
                    width: 100%;
                    height: 46px;
                    background: #FFFFFF;
                    border: 1px solid #D7DCE0;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 46px;
                    font-size: 16px;
                    color: #333333;
                    margin-bottom: 20px;
                    cursor: pointer;
                }

                .code_css{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
        }

        .navigation_bar_title{
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 30px;
        }
        .item_tags{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            >span{
                padding: 4px 8px;
                background: #F6F6F6;
                border-radius: 4px;
                font-size: 12px;
                color: #999999;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .jbxx_css{
            display: flex;
            >div:nth-child(1){
                margin-right: 40px;
                color: #999999;
            }
            >div:nth-child(2){
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #F2F2F2;
                padding-bottom: 10px;
                margin-bottom: 30px;
                >span{
                    width: 35%;
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 20px;
                }
            }
        }
        .ptss_css{
            display: flex;
            margin-bottom: 50px;
            >div:nth-child(1){
                margin-right: 40px;
                color: #999999;
            }
            >div:nth-child(2){
                width: 700px;
                display: flex;
                flex-wrap: wrap;
                >div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 64px;
                    margin-bottom: 20px;
                    width: 60px;
                    img{
                        width: 28px;
                        height: 24px;
                    }
                    span{
                        font-size: 15px;
                        color: #666666;
                    }
                }
            }
        }
        .fyms_css{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            >div{
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                >span:nth-child(1){
                    font-size: 20px;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 10px;
                }
                >span:nth-child(2){
                    font-size: 14px;
                    color: #666666;
                }
            }
        }
        .describe{
            padding: 28px 20px;
            background: #F2F2F2;
            font-size: 15px;
            color: #666666;
            margin-bottom: 50px;
        }


        .room_card_list{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 50px;
            .room_card_item{
                width: 368px;
                height: 450px;
                box-sizing: border-box;
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                border-radius: 8px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                .item_img{
                    height: 270px;
                    border-radius: 8px 8px 0px 0px;
                    overflow: hidden;
                    >img{
                        width: 100%;
                        height: 100%;
                    }
                }
                // >div:nth-child(2){
                div:nth-child(2){
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 15px 20px;
                    box-sizing: border-box;
                    >span:nth-child(1){
                        font-size: 18px;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 10px;
                    }
                    >span:nth-child(2){
                        font-size: 14px;
                        color: #999999;
                        margin-bottom: 10px;
                    }
                    .item_tags{
                        display: flex;
                        margin-bottom: 10px;
                        >span{
                            padding: 4px 8px;
                            background: #F6F6F6;
                            border-radius: 4px;
                            font-size: 12px;
                            color: #999999;
                            margin-right: 10px;
                        }
                    }
                    >span:nth-child(4){
                        font-size: 20px;
                        color: #FF5717;
                    }
                }
            }
            .room_card_item:hover{
                border-color: #FED54C;
                >div:nth-child(2){
                    >span:nth-child(1){
                        color: #FED54C;
                    }
                }
            }
        }

        .room_map{
            position: relative;
            .map_popup{
                position: absolute;
                top: 20px;
                right: 20px;
                width: 420px;
                height: 381px;
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 0px rgba(127, 127, 127, 0.2);
                box-sizing: border-box;
                z-index: 99;
                display: flex;
                flex-direction: column;
                >div:nth-child(1){
                    height: 66px;
                    display: flex;
                    border-bottom: 1px solid #EDEDED;
                    >div{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        >img{
                            width: 21px;
                            height: 21px;
                            margin-bottom: 5px;
                        }
                        >span{
                            font-size: 14px;
                            color: #666666;
                        }
                    }
                    .active_popup{
                        background-color: #FFD542;
                        span{
                            color: #fff!important;
                        }
                    }
                }
                >div:nth-child(2){
                    display: flex;
                    flex-direction: column;
                    max-height: 314px;
                    overflow-y: auto;
                    >div{
                        margin: 20px 20px 0 20px;
                        padding-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        border-bottom: 1px solid #EDEDED;
                        span{
                            font-size: 14px;
                            color: #333333;
                        }
                    }
                }
            }





            .BMap_cpyCtrl {
                display: none;
            }
            .anchorBL {
                display: none;
            }
        }

    }


    .subscribe{
        .el-input{
            width: 100%;
            .el-input__inner{
                height: 36px;
                line-height: 36px;
            }
        }
        .kfsj{
            display: flex;
            justify-content: space-between;
            span{
                width: 72px;
                height: 36px;
                box-sizing: border-box;
                background: #FFFFFF;
                border: 1px solid #EBEDF0;
                border-radius: 4px;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
            }
            .active_kfsj{
                background: #FFF9E3;
                border-color: #FFD542;
                color: #FFD542;
            }
        }
    }


    .login{
        .el-dialog__body{
            padding: 30px 60px 10px 60px;
        }
        .login_input{
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid #E4E5E6;
            border-radius: 4px;
            margin-bottom: 16px;
            img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            .el-input__inner{
                height: 30px;
                line-height: 30px;
                border: none;
                border-left: 1px solid #E4E5E6;
                border-radius: 0;
            }
        }

        .dialog-footer{
            display: flex;
            flex-direction: column;
            padding-left: 40px;
            padding-right: 40px;
            button{
                margin: 0;
                width: 100%!important;
                height: 50px!important;
                background: #FFD542;
                border-radius: 4px;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
    .register{
        .register_input{
            .el-input__inner{
                height: 50px;
                line-height: 50px;
                margin-bottom: 16px;
            }
        }
        .yzm{
            display: flex;
            justify-content: space-between;
            .el-input{
                width: 268px;
            }
            .el-button{
                height: 50px;
                background-color: #FFD542;
                border-color: #FFD542;
            }
        }
  
    }




    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        position: relative;
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .swiper-slide:after{
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.3;
    }

    .swiper-slide img,video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .swiper_active_css:after{
        opacity: 0;
    }

    .ql-editor {
        min-height: 500px !important;
    }


    .el-dialog{
        border-radius: 12px;
        .el-dialog__header{
            background: #ECEFF3;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .el-dialog__headerbtn{
                font-size: 20px;
            }
        }
    }

    .el-dialog__header{
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        background: #fff!important;
    }

    .el-dialog__footer{
        text-align: center;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        .dialog-footer{
            .el-button{
                width: 100px;
                height: 35px;
                padding: 0;
            }
            .btn_close{
                background-color: #f6f6f6;
                border-color: #e8e8e8;
                color: #666;
            }
            .btn_submit{
                background-color: #FFD542!important;
                border-color: #FFD542!important;
                color: #fff!important;
            }
        }
    }





}
</style>
