// 计算距离
export function distance(from = {lng: 0, lat: 0}, to = {lng: 0, lat: 0}, unit = true) {
    const lng1 = from.lng
    const lat1 = from.lat

    const lng2 = to.lng
    const lat2 = to.lat

    const radLat1 = lat1*Math.PI / 180.0;
    const radLat2 = lat2*Math.PI / 180.0;
    const a = radLat1 - radLat2;
    const  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
        Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
    s = s *6378.137 ;// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;

    s = s * 1000

    if (unit) {
        if (isNaN(s)) {
            return 0+'m';
        }
        if (s > 1000) {
            //    大于1000米时
            s = Math.floor(s/1000 * 100) / 100;
            s = s + 'km'
        } else {
            //    小于1000米直接返回
            s = s + 'm'
        }
    } else {
        if (isNaN(s)) {
            return 0;
        }
    }
    return s;
}

// 排序处理
export function handleSort(property) {
    return function (a, b) {
        const v1 = a[property]
        const v2 = b[property]
        return v1 - v2
    }
}
