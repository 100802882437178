<template>
    <div class="agreement">
        <p style="margin-right:14px;margin-bottom:10px;margin-left:14px;text-indent:0;text-align:center;background:rgb(255,255,255)">
    <strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 19px">用户注册协议</span></strong>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">一、定义</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">平台，是指您当前所浏览的网站平台。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">用户，包含注册用户和非注册用户，以下亦称为</span><span style="font-family:宋体">“您”。注册用户是指通过我们平台完成全部注册程序后，使用我们平台服务或我们网站资料的用户。非注册用户是指未进行注册、访问我们平台直接使用我们平台服务或我们网站资料的用户。</span></span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">感谢您选择广州满租科技发展有限公司的服务。广州满租科技发展有限公司网站用户注册协议由广州满租科技发展有限公司（以下简称</span><span style="font-family:宋体">“我们”）和您签订。</span></span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">二、协议的效力</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1、在您注册成为我们的用户过程中，您需要完成我们的注册流程并通过点击同意确认接收并遵守用户注册协议和隐私政策的全部条款和条件，请您务必仔细阅读、充分理解协议的条款内容后再点击同意确认。您完成在我们网站的注册并使用我们提供的服务即视为已接受并同意受本用户注册协议的约束。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2、本协议内容包括本协议正文及《隐私政策》，所有我们已经发布的或将来可能发布的规则为本协议不可分割的组成部分，与本协议具有同等法律效力。除另行明确声明外，任何我们提供的服务（以下称为“我们平台服务”）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用我们平台服务；如您继续访问和使用我们平台服务，即视为您已确知并完全同意本协议各项内容。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3、我们有权根据国家法律法规的更新、产品和服务规则的调整需要不时地制订、修改本协议及/或各类规则，并提前以网站公示的方式进行公示。如您继续使用我们平台服务的，即表示您接受经修订的协议和规则。如发生有关争议时，以我们最新的相关协议和规则为准。如您不接受经修订的协议和规则，停止使用我们平台服务的，您可以选择注销账号，注销账号时您可以通过邮箱</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">153674868@qq.com</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">联系我们，核对信息确认此账号为本人操作使用后方可以进行注销，我公司承诺会在</span><span style="font-family:宋体">2个工作日内清除所有个人信息资料。</span></span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">三、注册</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1、您确认，在您完成注册程序或以其他我们允许的方式实际使用我们平台服务时，您应当是具备相应民事行为能力的自然人（十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人）、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人（以下统称&quot;监护人&quot;）应承担因此而导致的一切后果，且我们有权注销您的账户。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2、我们非常重视对青少年个人信息搜集和使用的安全性的保护。我们建议，任何未满18周岁的未成年人参加网上活动应事先取得其监护人可经查证的同意并遵守《全国青少年网络文明公约》。监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3、在您签署本协议，完成注册程序时，您应对您的用户名、登录密码的安全，以及对通过您的账户和密码实施的行为负责；因此所衍生的任何损失或损害，我们无法也不承担任何责任。除非有法律规定或行政司法机关的指令，且征得我们的同意，否则您的用户名、登录密码不得以任何方式转让、借用、赠与、继承（与账户相关的财产权益除外）或在第三方平台上进行展示或售卖。否则，由此给您（或我们、任何第三方）造成的一切损失，概由您自行承担（或者负责赔偿）。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">4、您在注册帐号或使用我们平台服务的过程中，应提供合法、真实、准确的个人资料，您填写的个人资料有变动的，应及时进行更新。如果因您提供的个人资料不合法、不真实、不准确的，您需承担因此引起的相应责任及后果，并且我们保留终止您使用我们各项服务的权利。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">5、您了解并同意，如您符合并且遵守本协议条款，在通过我们平台完成注册程序之后，即可成为我们平台注册用户。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">6、您不得通过任何手段恶意注册我们网站帐号，包括但不限于以牟利、炒作、套现等为目的多个账号注册。您亦不得盗用其他用户帐号。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">7、您了解并同意，一经注册用户账号，即视为您同意我们可以通过发送电子邮件的方式向您注册时填写的电子邮箱发送、告知相应的产品服务广告信息；您如果不同意接收相关信息，您可以通过相应的退订功能或相关提示进行退订。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">四、我们平台服务使用规范</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1、通过我们平台，您可以按照我们的规则发布信息。但所发布之信息不得含有如下内容：</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1)反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律、行政法规实施的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2)煽动危害国家安全、泄露国家秘密、颠覆国家政权，推翻社会主义制度的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3)煽动分裂国家、破坏国家统一、损害国家荣誉和民族利益的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">4)煽动民族仇恨、民族歧视，破坏民族团结的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">5)捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">6)进行政治宣传或破坏国家宗教政策、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">7)公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">8)损害国家机关信誉的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">9)其他违反宪法和法律法规的；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2、在接受我们服务的过程中，您不得从事下列行为：</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1)在使用我们平台服务过程中实施的所有行为均遵守国家法律、法规等规范文件及我们平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保我们免于因此产生任何损失或增加费用。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2)不发布国家禁止发布的信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3)不对我们平台上的任何数据作商业性利用，包括但不限于在未经我们事先书面同意的情况下，以复制、传播等任何方式使用我们平台站上展示的资料。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">4)不使用任何装置、软件或例行程序干预或试图干预我们平台的正常运作或正在我们平台上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸我们平台网络设备的行动。</span>
</p>
<p style="margin: 7px 0 10px 12px;text-indent: 15px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3、您同意，在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，您及时通知我们。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知我们并同时提供如下信息和材料：</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1)侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2)您是所述的版权或者其他权利的合法拥有者的权利证明；</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3)您的联系方式，包括联系人姓名，地址，电话号码和电子邮件；</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px;background: rgb(255, 255, 255)"><span style="font-family:宋体">五、终止协议</span></span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">1、我们将在本平台公布并不时修订隐私权条款，隐私权条款构成本协议的有效组成部分。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">2、在您注销本平台账号时或后期不继续使用，我们将停止使用并删除您的信息。</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3、您在本平台删除账号或后期不继续使用，注销账号时您可以通过邮箱</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">153674868@qq.com</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">联系我们，核对信息确认此账号为本人操作使用后方以进行注销，我们承诺会在</span><span style="font-family:宋体">2个工作日内清除所有个人信息资料。</span></span>
</p>
<p style="margin: 7px 14px;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 14px;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 14px;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 14px;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin-top: 7px;margin-right: 14px;margin-bottom: 7px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<h3 style="margin-top:20px;margin-right:14px;margin-bottom:10px;margin-left:14px;text-indent:0;text-align:center;background:rgb(255,255,255)">
    <strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 19px">隐私政策</span></strong>
</h3>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">本站（以下亦称</span><span style="font-family:宋体">“我们”）深知个人信息对您的重要性，我们尊重并保护所有使用我们平台服务的用户的个人信息，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》（下称“本隐私政策”）。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;一、我们处理个人信息的法律依据</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">本隐私政策制定的法律依据为《中华人民共和国消费者权益保护法》、《中华人民共和国网络安全法》、《中华人民共和国电子商务法》、《信息安全技术个人信息安全规范》以及其他涉及公民个人信息的相关法律法规。我们会基于本隐私政策提示的功能收集您的个人信息。某些情况下，如果涉及其他信息的收集我们会单独向您出示个人信息保护说明条款。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">二、本隐私政策的适用范围</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">本隐私政策适用于您使用本平台的产品或服务时使用。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">三、我们如何收集和使用您的个人信息</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">个人敏感信息一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策涉及到的个人敏感信息我们将加粗并加下划线的方式提示您注意阅读。在您向我们提供任何属于敏感信息的个人信息前，请您清楚考虑该等提供是恰当的并且同意您的个人敏感信息可按本隐私政策所述的目的和方式进行处理。我们会在得到您的同意后收集和使用您的敏感信息以实现与我们业务相关的功能，并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">原则上，我们仅会出于本隐私政策所述的以下目的，收集和使用您的个人信息。我们是信息发布平台，所涉信息较多，如果超过以下目的收集和使用您的个人信息时我们会单独向您提示并征得您的同意。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3.1注册要提供的信息</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">您自行注册成为我们的用户。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">当您注册我们账户时，您需要输入您的</span><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">手机号码</span></strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">。</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">您可以通过手机号接收短信验证码完成注册。</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">当您的账户密码遗失时，可以通过注册</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">手机号收到</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">的链接重置密码。如您拒绝提供您的</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">手机号码</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">，您将无法注册成功且无法继续使用我平台的相关服务，但不影响您通过网站页面进行部分信息的浏览、搜索。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3.2发布要提供的信息</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">我公司平台最主要的功能就是信息发布功能。当您使用发布功能发布信息时，我们也需要收集您的个人信息。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">如您是已注册用户，您发布信息时，必须要</span><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">标题、</span></strong><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">价格、图片、</span></strong><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">内容详情</span></strong><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">描述</span></strong><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">、联系人、</span></strong><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">手机号码</span></strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">。</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">填写标题、价格、图片、内容详情描述是为了能让需求者了解您发布的信息；</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">收集联系人、手机号码是为了方便用户联系您。如果您不提供以上信息，您将不能进行信息发布。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:24px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">3.3客户服务</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">当您向我们申诉或进行咨询时，为了方便与您联系或帮助您解决问题，我们需要您提供用户名、电子邮件信息。如您拒绝提供上述信息，部分功能会无法使用，同时无法向您及时反馈申诉或咨询结果。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">会员在本平台删除账号或后期不继续使用，注销账号时会员可以通过邮</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">箱</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">153674868@qq.com</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">联系我们，核对信息确认此账号为本人操作使用后方以进行注销，我司承诺会在</span><span style="font-family:宋体">2个工作日内清除所有个人信息资料。</span></span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">四、如何访问和编辑您的信息</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">我们将采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们会要求您进行身份验证，以保障账户安全。</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">4.1如何访问您的发布信息</span>
</p>
<p style="margin: 7px 0;text-indent: 28px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">您可以通过网站右上角的</span><span style="font-family:宋体">“发布”进行信息发布，您要确保您所发布的信息合法真实有效，以便需求者可以找到您。您成功发布信息后，后台人员会对信息进行审核，审核通过后，即可在栏目中进行查看。</span></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><br/></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;&nbsp;4.2如何删除编辑发布的信息</span>
</p>
<p style="margin: 7px 0;text-indent: 28px">
    <a href="mailto:%E5%A6%82%E5%8F%91%E7%8E%B0%E6%82%A8%E7%9A%84%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF%EF%BC%8C%E6%82%A8%E5%8F%AF%E4%BB%A5%E6%A0%B9%E6%8D%AE%E9%A1%B5%E9%9D%A2%E6%8F%90%E7%A4%BA%E8%87%AA%E5%8A%A9%E4%BF%AE%E6%94%B9%E6%82%A8%E7%9A%84%E4%BF%A1%E6%81%AF%EF%BC%8C%E6%82%A8%E4%B9%9F%E5%8F%AF%E4%BB%A5%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC%E7%9A%84%E5%AE%A2%E6%9C%8D%E7%94%B5%E8%AF%9D400-998-2019%E6%88%96%E9%80%9A%E8%BF%87%E5%8F%91%E9%80%81%E9%82%AE%E4%BB%B6%EF%BC%88suxt@huitouche.com%EF%BC%89%E7%9A%84%E5%BD%A2%E5%BC%8F%E8%A6%81%E6%B1%82%E6%9B%B4%E6%AD%A3%E3%80%82"><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">如您已经是注册用户，发现您的信息有误需要编辑或者删除，您可以通过登录账号，在</span><span style="font-family:宋体">“</span></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">审核</span></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">”</span></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">-“所有信息”</span></span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">里面进行修改或者删除操作。</span></span></a>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">五、我们如何保护您的个人信息</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:32px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于</span><span style="font-family:宋体">SSL、信息加密存储、数据中心的访问控制。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:24px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">5.1数据加密：我们对于用户的用户名、电话、QQ号、注册邮箱进行加密存储，保证用户基本信息不会被恶意获取；</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:24px;background:rgb(255,255,255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">5.2身份鉴别：我们通过校验账号密码或者账号绑定邮箱，进行用户身份合法性鉴别，防止非经授权的介入；</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">5.3账号保护：您的账户均有安全保护功能，请妥善保管您的账户及密码信息。对用户密码进行加密的安全措施确保您的信息不丢失，不被滥用和变造。如因您自己的原因导致账户及密码信息泄露而造成的任何法律后果需由您本人负责。</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">我们已经成立隐私保护办公室并配有个人信息保护专门负责人员，如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，请通过以下渠道联系我们：</span>
</p>
<p style="margin-top: 7px;margin-right: 14px;margin-bottom: 7px;text-indent: 32px">
    <a href="mailto:%E9%82%AE%E7%AE%B1%E5%9C%B0%E5%9D%80suxt@huitouche.com"><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">邮箱地址</span></span><span style="font-family: 宋体;color: rgb(0, 0, 0);letter-spacing: 0;font-size: 12px;background: rgb(255, 255, 255)"><span style="font-family:宋体">153674868@qq.com</span></span></a>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">客服部门将会同隐私保护办公室及时答复您。</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">为保障您的信息安全，我们需要先验证您的身份和凭证资料。我们已经建立客户投诉管理机制，包括跟踪流程。一般来说，我们将在验证通过后的三个工作日内处理完成，特殊情形下最长将在不超过三十天或法律法规规定期限内作出答复。如您不满意我们的答复，还可以向消费者权益保护部门投诉或向有管辖权的法院提起诉讼。</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">六、我们向您发送的邮件和信息</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">6.1邮件和信息推送</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">您在使用我们的服务时，我们会使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">6.2与服务有关的公告</span>
</p>
<p style="margin: 7px 0;text-indent: 24px">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">因系统维护而暂停某一项服务时，我们会向您发出与服务有关的公告。您无法取消这些与服务有关、性质不属于推广的公告。</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">七、变更</span>
</p>
<p style="margin: 7px 0 10px;text-indent: 32px;background: rgb(255, 255, 255)">
    <span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">我们会适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。如您不接受经修订的条款，停止使用我们平台服务的，您可以选择注销账号，注销账号时您可以通过邮箱</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px">153674868@qq.com</span><span style="font-family: 宋体;letter-spacing: 0;font-size: 12px"><span style="font-family:宋体">联系我们，核对信息确认此账号为本人操作使用后方可以进行注销，我公司承诺会在</span><span style="font-family:宋体">2个工作日内清除所有个人信息资料。</span></span>
</p>
<p>
    <br/>
</p>
    </div>
</template>
<script>
export default {
    name:'agreement',
    data(){
        return{

        }
    },
}
</script>
<style lang="scss" scoped>
.agreement{
    background-color: #fff;
}
</style>
