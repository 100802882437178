import Vue from "vue";
import axios from "axios";
// import { countBy } from "lodash";

// let __token__ = localStorage.getItem("youyou_token");
let __token__ = '';

let loginAlertTimes = 0;

const instance = axios.create({
    timeout: 50000,
    baseURL: "https://wxapp.manzu365.com/"
        // baseURL: "http://192.168.88.186:8027/"
});

// 请求拦截
instance.interceptors.request.use(
    function(config) {
        config.headers = getHeader();
        return config;
    },
    function(err) {
        return Promise.reject(err);
    }
);

// 响应拦截
instance.interceptors.response.use(
    function(response) {
        if (response.status === 200) {
            if (response.data.err !== 0) {
                if (response.data.err === 99) {
                    clearToken();
                    if (loginAlertTimes < 1) {
                        loginAlertTimes++;
                        Vue.prototype.$alert(response.data.msg, {
                            type: "error",
                        });
                        setTimeout(() => {
                            location.href = "/#/login";
                        }, 2000);
                    }
                } else {
                    Vue.prototype.$alert(response.data.msg, {
                        type: "error",
                    });
                }
                return Promise.reject(response);
            }
            return Promise.resolve(response);
        } else {
            Vue.prototype.$alert("出错了", {
                type: "error",
            });
            return Promise.reject(response);
        }
    },
    function(err) {
        console.log(1111, err);
        Vue.prototype.$alert("网络异常", {
            type: "error",
        });
        return Promise.reject(err);
    }
);

export function getToken() {
    __token__ = localStorage.getItem("youyou_token") || '';
    // __token__ = '';
    return __token__;
}

export function setToken(token) {
    __token__ = token;
    localStorage.setItem('youyou_token', __token__);
}
export function clearToken() {
    __token__ = null;
    localStorage.removeItem('youyou_token');
}
export function getHeader() {
    return {
        "App-Token": getToken(),
        "Device-Type": 'pc',
        "Device-Ver": 'pc1',
        "App-Ver": '1.0',
    };
}

// export function setRole(role) {
//     localStorage.setItem('my_role', role);
//     Vue.prototype.$myRole = role
// }

// export function creatHxSelect() {
//     let a = [];
//     for (var i = 1; i <= 5; i++) {
//         let c = [];
//         c
//         for (var i3 = 0; i3 <= 4; i3++) {
//             c.push({
//                 value: i3,
//                 label: i3 + '卫'
//             })
//         }
//         let b = [];
//         for (var i2 = 0; i2 <= 4; i2++) {
//             b.push({
//                 value: i2,
//                 label: i2 + '厅',
//                 children: c
//             })
//         }
//         a.push({
//             value: i,
//             label: i + '房',
//             children: b
//         })
//     }
//     return a;
// }

// export function deepCopy(obj) {
//     var newobj = obj.constructor === Array ? [] : {};
//     if (typeof obj !== 'object') {
//         return obj;
//     } else {
//         for (var i in obj) {
//             if (typeof obj[i] === 'object') {
//                 newobj[i] = deepCopy(obj[i]);
//             } else {
//                 newobj[i] = obj[i];
//             }
//         }
//     }
//     return newobj;
// }

export function post(path = '', data = {}, obj = {}, objKey = "") {
    return new Promise(function(resolve, reject) {
        instance.post(path, data)
            .then(function(response) {
                // console.log(response.data);
                if (obj) {
                    obj[objKey] = false;
                }
                resolve(response.data)
            })
            .catch(function(error) {
                if (obj) {
                    obj[objKey] = false;
                }
                reject(error, data)
            })
    })
}