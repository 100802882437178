<template>
    <el-main class="home_page" v-loading="loading">
        <div class="top_banner">
            <div class="navigation_bar">
                <div>
                    <img src="../assets/img/logo_mz.png" alt="" style="width:302px;height:34px;">
                    <div style="display: flex;align-items: center;">
                        <el-input
                            placeholder="请输入区域、小区名"
                            prefix-icon="el-icon-search"
                            v-model="search"
                            @keyup.enter.native="search_gjz"
                            style="width:200px;">
                        </el-input>
                        <ul>
                            <li v-for="(item,index) in nav_top" :key="index">
                                <router-link v-if="item.name!=='发布'" :to="{path:item.url}" :class="nav_top_active==index?'nav_active':''" style="text-decoration: none;outline: none;">{{item.name}}</router-link>
                                <div v-else :class="nav_top_active==index?'nav_active':''" @click="rich_text_show" class="fbfy fbfy_after" style="text-decoration: none;outline: none;">{{item.name}}</div>
                            </li>
                            <li class="login_css">
                                <img src="../assets/img/tx@2x.png" alt="" style="width:22px;height:22px;">
                                <span v-if="!username" @click="login_show">登录/注册</span>
                                <div v-else>
                                    <span style="margin-right:10px;">{{username}}</span>
                                    <span @click="exit">退出</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="top_banner_card">
                <div>轻松优惠租好房</div>
                <!--  -->
                <div>
                    <img src="../assets/img/banner_translate.png" alt="">
                </div>
                <!-- <div>
                    <div class="left_logo">
                        <img src="../assets/img/yy_logo.png" alt="">
                    </div>
                    <div class="code">
                        <img src="https://image.manzu365.com/uploads/image/20220426015209_75166.png" alt="" style="width: 92px;height: 92px;border-radius: 4px;margin-right: 16px;">
                        <div>
                            <div style="color: #333333;font-size: 18px;margin-bottom:10px;">小程序二维码</div>
                            <div style="color: #999999;font-size: 15px;">扫一扫更多房源</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="subject">
            <div class="screen">
                <div class="condition_item">
                    <span>位置：</span>
                    <div>
                        <div class="position">
                            <div v-for="(item,index) in position_arr" :key="index" :class="position_active==index?'position_active':''">
                                <span @click="swicth_position(index);">{{item.name}}</span>
                                <img src="../assets/img/sq@2x.png" alt="" style="width:14px;height:14px;">
                            </div>
                        </div>
                        <div class="option_item">
                            <span @click="del_wz" :class="districtId==''&&subwayId==''?'active':''">不限</span>
                            <span v-show="position_active==0" @click="active_districtId(item)" :class="districtId==item.value?'active':''" v-for="(item,index) in config.districtSelects" :key="index+'district'">{{item.name}}</span>
                            <span v-show="position_active==1" @click="active_subwayId(item)" :class="subwayId==item.value?'active':''" v-for="(item,index) in config.subwaySelects" :key="index+'subway'">{{item.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="condition_item">
                    <span style="display: inline-block;padding-top: 6px;">租金：</span>
                    <div>
                        <div class="option_item" style="align-items: center;">
                            <span @click="del_zj" :class="minMaxPrice==''?'active':''" style="margin-bottom: 0;">不限</span>
                            <span v-for="(item,index) in config.PriceSelects" :key="index" @click="active_minMaxPrice(item)" :class="minMaxPrice==item.value?'active':''" style="margin-bottom: 0;">{{item.name}}</span>
                            <div class="zj_css">
                                <el-input v-model="price_min" placeholder=""></el-input>
                                <span class="separator"></span>
                                <el-input v-model="price_max" placeholder=""></el-input>
                                <span style="margin-right:19px;">&emsp;元</span>
                                <span @click="custom_minMaxPrice" style="color: #FF5717;cursor: pointer;">确定</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="condition_item">
                    <span>户型：</span>
                    <div>
                        <div class="option_item">
                            <span @click="del_hx" :class="fx==''?'active':''">不限</span>
                            <span v-for="(item,index) in config.hx" :key="index" @click="active_fx(item)" :class="fx==item.value?'active':''">{{item.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="condition_item">
                    <span>特色：</span>
                    <div>
                        <div class="option_item">
                            <span @click="roomTag=[];ts_tag=[];get_data();" :class="roomTag.length==0?'active':''">不限</span>
                            <span v-for="(item,index) in config.roomTagSelects" :key="index" @click="active_roomTag(item)" :class="roomTag.indexOf(item.value)!=-1?'active':''">{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div style="display: flex;margin-bottom:40px;" v-show="wz_tag!=''||zj_tag!=''||hx_tag!=''||ts_tag.length!=0">
                    <div class="screen_select" style="flex:1;">
                        <span style="display: inline-block;padding-top: 2px;">您已选择：</span>
                        <div>
                            <div class="tags" v-show="wz_tag!=''">
                                <span>{{wz_tag}}</span>
                                <img @click="del_wz" src="../assets/img/sc@2x.png" alt="" style="width:12px;height:12px;">
                            </div>
                            <div class="tags" v-show="zj_tag!=''">
                                <span>{{zj_tag}}</span>
                                <img @click="del_zj" src="../assets/img/sc@2x.png" alt="" style="width:12px;height:12px;">
                            </div>
                            <div class="tags" v-show="hx_tag!=''">
                                <span>{{hx_tag}}</span>
                                <img @click="del_hx" src="../assets/img/sc@2x.png" alt="" style="width:12px;height:12px;">
                            </div>
                            <div class="tags" v-show="roomTag.length!=0" v-for="(item,index) in ts_tag" :key="index">
                                <span>{{item.name}}</span>
                                <img @click="del_ts(item)" src="../assets/img/sc@2x.png" alt="" style="width:12px;height:12px;">
                            </div>
                        </div>
                    </div>
                    <span @click="empty_condition" style="width:80px;cursor: pointer;font-size: 14px;color: #333333;text-align: right;">清空条件</span>
                </div>

                <div class="list_sort">
                    <span v-for="(item,index) in px_arr" :key="index" :class="[item.name=='价格'||item.name=='面积'?'sort_icon':'',item.name==px_active?px_active_class:'']" @click="set_list_class(item)">{{item.name}}</span>
                </div>

                <el-divider></el-divider>
            </div>

            <div class="room_card_subject">
                <div class="room_card_list" v-loading="loading_list">
                    <div v-for="(item,index) in list_data.list" :key="index" :style="(index+1)%3!=0?'margin-right: 25px;':''" class="room_card_item">
                        <router-link :to="{path:'/room_details',query:{id:item.id}}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                            <div class="item_img">
                                <img :src="item.coverImage" alt="">
                            </div>
                            <div>
                                <span>{{item.title}}</span>
                                <span>{{item.stationTitle}}</span>
                                <div class="item_tags">
                                    <span v-for="(item_ts,index_ts) in item.roomTags" :style="index_ts>=3?'display: none;':''" :key="index_ts">{{item_ts}}</span>
                                    <span v-if="item.roomTags.length>=4">…</span>
                                </div>
                                <span>¥{{item.roomPrice}}元/月</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="table-bottom-page">
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-size="limit"
                        layout="prev, pager, next, jumper"
                        :total="list_data.count">
                    </el-pagination>
                </div>
            </div>
        </div>

        <company-information></company-information>


        <el-dialog
        :title="dialog_title"
        custom-class="login register"
        :visible.sync="login_dialog"
        width="520px"
        top="20vh"
        :close-on-click-modal="false"
        :before-close="lr_close">
            <div v-show="dialog_title=='登录'">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/sjh@2x.png" alt="">
                            <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/mm@2x.png" alt="">
                            <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div v-show="dialog_title=='注册'" class="register_input">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                    </el-col>
                    <el-col :span="24" class="yzm">
                        <el-input v-model="code" placeholder="请输入验证码"></el-input>
                        <el-button @click="obtain_code" type="primary" :disabled="code_disabled">{{code_text}}</el-button>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="repassword" placeholder="请再次输入密码" type="password"></el-input>
                    </el-col>
                </el-row>
                <el-checkbox v-model="agreement_val">
                    <router-link :to="{path:'/agreement'}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                    我接受用户协议和隐私政策
                    </router-link>
                </el-checkbox>
            </div>

            <span v-show="dialog_title=='登录'" slot="footer" class="dialog-footer">
                <el-button @click="login_submit" type="primary" class="btn_submit">登 录</el-button>
                <el-button @click="login_handleClose" class="btn_close">注 册</el-button>
            </span>

            <span v-show="dialog_title=='注册'" slot="footer" class="dialog-footer">
                <el-button @click="register_submit" :disabled="!agreement_val" type="primary" class="btn_submit">注 册</el-button>
                <el-button @click="register_handleClose" class="btn_close">返回登录</el-button>
            </span>
        </el-dialog>

        <el-dialog
        title="发布房源"
        custom-class="rich_text"
        :visible.sync="rich_text_dialog"
        width="1000px"
        top="5vh"
        :close-on-click-modal="false"
        :before-close="rich_text_handleClose">

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"> 
                <el-form-item label="房源标题：" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
                <!-- 富文本编辑器 -->
                <el-form-item label="房源内容：" prop="content">
                    <!-- 失去焦点时手动校验该字段 (当前 content 属性值) -->
                    <el-upload class="avatar-uploader" :action="uploadUrl" name="file" :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeUpload" :multiple="true" style="display:none;"></el-upload>
                        <quill-editor
                            ref="QuillEditor"
                            :options="editorOption"
                            @blur="$refs.ruleForm.validateField('content')"
                            v-model="ruleForm.content"/>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="rich_text_handleClose" class="btn_close">取 消</el-button>
                <el-button @click="rich_text_submit" type="primary" class="btn_submit">发 布</el-button>
            </span>
        </el-dialog>




    </el-main>
</template>
<script>
import { post,setToken,getToken } from "@/api/request";
import companyInformation from '../components/companyInformation.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // 加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'],                      //引用，代码块
  [{ 'header': 1 }, { 'header': 2 }],               // 几级标题
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],     // 有序列表，无序列表
  [{ 'script': 'sub' }, { 'script': 'super' }],      // 下角标，上角标
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // 缩进
  [{ 'direction': 'rtl' }],                         // 文字输入方向
  [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],// 标题
  [{ 'color': [] }, { 'background': [] }],          // 颜色选择
  [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],// 字体
  [{ 'align': [] }], // 居中
  ['clean'],                                       // 清除样式,
  ['link', 'image'],  // 上传图片、上传视频
]

export default {
    name:'index',
    components:{
        companyInformation,
    },
    data(){
        return{
            loading:false,
            nav_top:[
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],
            nav_top_active:0,

            position_arr:[
                {name:'按区域',val:0},
                {name:'按地铁线',val:1},
            ],
            position_active:0,

            config:{},
            price_min:'',
            price_max:'',

            //选中的值
            districtId:'',
            subwayId:'',
            wz_tag:'',
            minMaxPrice:'',
            zj_tag:'',
            fx:'',
            hx_tag:'',
            roomTag:[],
            ts_tag:[],
            
            page:1,
            limit:12,



            px_arr:[
                {name:'综合排序'},
                {name:'最新上架'},
                {name:'价格',val:1},
                {name:'面积',val:1},
            ],
            px_active_class:'list_sort_active',     
            px_active:'综合排序',
            
            loading_list:false,
            list_data:{
                list:[],
                count:1
            },


            username:'',
            dialog_title:'登录',
            login_dialog:false,
            tel:'',
            code:'',
            passwrod:'',
            repassword:'',

            code_disabled:false,
            code_text:'获取验证码',


            rich_text_dialog:false,
            ruleForm: {
                content: '', 
                title: '', 
            },
            rules: {
                content: [
                    { required: true, message: '请输入文本', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ],
            },


            search:'',

            editorOption: {
                placeholder: '请在这里输入',
                theme: 'snow', //主题 snow/bubble
                modules: {
                history: {
                    delay: 1000,
                    maxStack: 50,
                    userOnly: false
                },
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                    image: function (value) {
                        if (value) {
                        // 调用element的图片上传组件
                        document.querySelector('.avatar-uploader input').click()
                        } else {
                        this.quill.format('image', false)
                        }
                    }
                    }
                }
                }
            },

            uploadUrl:'https://wxapp.manzu365.com/api/com/upload',


            agreement_val:false,

        }
    },
    created(){
        this.loading = true;
        this.$axios.all([this.get_config(),this.get_data()]).then(()=>{
            this.loading = false;
        }).catch(()=>{
            this.loading = false;
        })
        if(getToken()){
            this.username = localStorage.getItem("username");
        }
        // this.get_config();
        // this.get_data();
    },
    methods:{
        search_gjz(){
            post('index/icp/search', {key:this.search}).then(res => {
                if (res.err == 0) {
                    this.$router.push({path: '/search'});
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        beforeUpload (file) { },
        uploadSuccess (res) {
            // 获取富文本组件实例
            let quill = this.$refs.QuillEditor.quill
            // 如果上传成功
            console.log(res);
            if (res.err === 0 && res.data.url) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', res.data.url)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败！')
            }
        },



        login_show(){
            this.login_dialog = true;
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
        },
        login_handleClose(){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '注册';
        },
        login_submit(){
            this.loading = true;
            post('index/icp/login', {
                tel:this.tel,
                passwrod:this.passwrod
            }).then(res => {
                if (res.err == 0) {
                    this.username = res.data.username;
                    setToken(res.data.token);
                    localStorage.setItem('username', res.data.username);
                    this.login_dialog = false;
                }
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        register_handleClose(){
            this.dialog_title = '登录';
        },
        obtain_code(){
            post('index/icp/sendcode', {
                tel:this.tel,
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发送成功!',
                        type: 'success'
                    });
                    this.code_disabled = true;
                    let num = 60;
                    this.timer = setInterval(()=>{
                        num--;
                        if(num > 0){
                            this.code_text = num+'秒';
                            this.$apply();
                        }else{
                            clearInterval(this.timer); //清除js定时器
                            this.code_text = '发送验证码';
                            this.code_disabled = false;
                            this.$apply();
                        }
                    },1000)
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        register_submit(){
            post('index/icp/reg', {
                tel:this.tel,
                code:this.code,
                passwrod:this.passwrod,
                repassword:this.repassword,
            }).then(res => {
                if (res.err == 0) {
                    this.tel = '';
                    this.code = '';
                    this.passwrod = '';
                    this.repassword = '';
                    this.dialog_title = '登录';
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        lr_close(done){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '登录';
            done();
        },

        exit(){
            this.nav_top = [
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],
            this.username = '';
            window.localStorage.clear();
            // localstore.removeSession(youyou_token);
            window.location.reload();
        },

        // 发布房源
        rich_text_show(){
            let token = getToken();
            if(!token){
                this.$message.error('请先登录!');
                return;
            }
            this.rich_text_dialog = true;
        },
        rich_text_handleClose(){
            this.rich_text_dialog = false;
            this.$refs.ruleForm.resetFields();
        },
        rich_text_submit(){
            let token = getToken();
            post('index/icp/fb', {
                ...this.ruleForm,
                token:token
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发布成功!',
                        type: 'success'
                    });
                    this.rich_text_dialog = false;
                    this.$refs.ruleForm.resetFields();
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })


        },


        get_config(){
            post('index/icp/filters', {}).then(res => {
                if (res.err == 0) {
                    this.config = res.data;
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
            
        },

        get_data(){
            this.loading_list = true;
            let px = '';
            if(this.px_active=='综合排序'){
                px = '';
            } else if(this.px_active=='综合排序'){
                px = 1;
            } else if(this.px_active=='价格'){
                if(this.px_arr[2].val==1){
                    px = 3;
                } else {
                    px = 2
                }
            } else if(this.px_active=='面积'){
                if(this.px_arr[3].val==1){
                    px = 5;
                } else {
                    px = 4
                }
            }
            post('index/icp/lists', {
                districtId:this.districtId?[this.districtId]:'',
                subwayId:this.subwayId?[this.subwayId]:'',
                minMaxPrice:this.minMaxPrice,
                roomTag:this.roomTag,
                fx:this.fx,
                px:px,
                page:this.page,
                limit:this.limit
            }).then(res => {
                if (res.err == 0) {
                    this.list_data = res.data;
                }
                this.loading_list = false;
            }).catch(error => {
                this.loading_list = false;
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        // 位置
        swicth_position(index){
            this.position_active=index;
            this.districtId = '';
            this.subwayId = '';
            this.wz_tag = '';
            this.page = 1;

            this.get_data();
        },
        active_districtId(item){
            this.districtId = item.value;
            this.wz_tag = item.name;

            this.page = 1;

            this.get_data();
        },
        active_subwayId(item){
            this.subwayId = item.value;
            this.wz_tag = item.name;
            
            this.page = 1;

            this.get_data();
        },
        // 删除位置搜索条件
        del_wz(){
            this.districtId = '';
            this.subwayId = '';
            this.wz_tag = '';

            this.page = 1;

            this.get_data();
        },


        // 租金范围
        active_minMaxPrice(item){
            this.minMaxPrice = item.value;
            this.zj_tag = item.name;

            this.page = 1;

            this.get_data();
        },
        custom_minMaxPrice(){
            if(!this.price_min||!this.price_max){
                this.$message({
                    message: '请输入完整的租金范围!',
                    type: 'warning'
                });
                return;
            }
            this.minMaxPrice = `${this.price_min}-${this.price_max}`;
            this.zj_tag = `${this.price_min}-${this.price_max}元`;
            this.price_min = this.price_max = '';

            this.page = 1;

            this.get_data();
        },
        del_zj(item){
            this.minMaxPrice = '';
            this.zj_tag = '';

            this.page = 1;

            this.get_data();
        },


        // 房型
        active_fx(item){
            this.fx = item.value;
            this.hx_tag = item.name;

            this.page = 1;

            this.get_data();
        },
        del_hx(){
            this.fx = '';
            this.hx_tag = '';

            this.page = 1;

            this.get_data();
        },


        // 特色
        active_roomTag(item){
            if(this.roomTag.indexOf(item.value)==-1){
                this.roomTag.push(item.value);
                this.ts_tag.push({name:item.name,value:item.value});
            } else {
                this.roomTag.splice(this.roomTag.indexOf(item.value),1);
                this.ts_tag.splice(this.ts_tag.indexOf(item.name),1);
            }

            this.page = 1;

            this.get_data();
        },
        del_ts(item){
            this.roomTag.splice(this.roomTag.indexOf(item.value),1);
            this.ts_tag.splice(this.ts_tag.indexOf(item.name),1);

            this.page = 1;

            this.get_data();
        },

        // 清空条件
        empty_condition(){
            this.districtId='';
            this.subwayId='';
            this.wz_tag='';
            this.minMaxPrice='';
            this.zj_tag='';
            this.fx='';
            this.hx_tag='';
            this.roomTag=[];
            this.ts_tag=[];

            this.page = 1;

            this.get_data();
        },


        // 综合排序切换
        set_list_class(item){
            if(item.name=='综合排序'){
                this.px_active = '综合排序';
                this.px_arr[2].val = 1;
                this.px_arr[3].val = 1;
                this.px_active_class = 'list_sort_active';
            } else if(item.name=='最新上架'){
                this.px_active = '最新上架';
                this.px_arr[2].val = 1;
                this.px_arr[3].val = 1;
                this.px_active_class = 'list_sort_active';
            } else if(item.name=='价格'){
                this.px_active = '价格';
                this.px_arr[3].val = 1;
                if(item.val==1){
                    item.val=2;
                    this.px_active_class = 'list_sort_active down';
                } else {
                    item.val=1;
                    this.px_active_class = 'list_sort_active up';
                }
            } else {
                this.px_active = '面积';
                this.px_arr[2].val = 1;
                if(item.val==1){
                    item.val=2;
                    this.px_active_class = 'list_sort_active down';
                } else {
                    item.val=1;
                    this.px_active_class = 'list_sort_active up';
                }
            }

            this.page = 1;

            this.get_data();
        },


        handleCurrentChange(val){
            this.page = val;
            this.get_data();
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep.home_page{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .top_banner{
        width: 100%;
        height: 560px;
        background-image: url('../assets/img/banner.png');
        background-repeat: no-repeat;
        background-size: 1920px 560px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        .navigation_bar{
            position: fixed;
            top: 0;
            width: 1920px;
            height: 64px;
            background-image: url('../assets/img/top_nav_bgc.png');
            background-repeat: no-repeat;
            background-size: 1920px 64px;
            display: flex;
            justify-content: center;
            z-index: 99;
            >div{
                width: 1156px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-input__inner{
                    height: 32px;
                    line-height: 32px;
                    border-radius: 16px;
                }
                .el-input__icon{
                    line-height: 32px;
                }
                ul{
                    display: flex;
                    li{ 
                        padding:0;
                        margin:0;
                        list-style:none;
                        a{
                            margin-right: 20px;
                            font-size: 16px;
                            padding: 9px 0;
                            color: #FFFFFF;
                            position: relative;
                        }
                        .fbfy{
                            margin-right: 20px;
                            font-size: 16px;
                            color: #FFFFFF;
                            position: relative;
                            cursor: pointer;
                        }
                        a:hover:after,
                        .fbfy:hover:after {
                            width: 120%;
                        }
                        a:after,
                        .fbfy:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            width: 0;
                            height: 2px;
                            background: #FFD542;
                            border-radius: 1px;
                            -webkit-transition: width .3s;
                            transition: width .3s;
                            will-change: width;
                        }
                        .nav_active{
                            position: relative;
                        }
                        .nav_active:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            width: 100%;
                            height: 2px;
                            background: #FFD542;
                            border-radius: 1px;
                        }

                        .fbfy_after:after{
                            bottom: -9px;
                        }
                    }
                }
            }

            .login_css{
                display: flex;
                color: #FFFFFF;
                img{
                    margin-right: 8px;
                }
                span{
                    cursor: pointer;
                }
            }
        }
        .top_banner_card{
            color: #FFFFFF;
            >div:nth-child(1){
                font-size: 66px;
            }
            >div:nth-child(2){
                text-align: center;
                margin-bottom: 15px;
            }
            >div:nth-child(3){
                padding: 15px;
                background-color: #fff;
                opacity: 0.9;
                display: flex;
                align-items: center;
                border-radius: 4px;
                .left_logo{
                    padding: 10px 35px 10px 20px;
                    border-right: 1px solid #DDDDDD;
                }
                .code{
                    display: flex;
                    align-items: center;
                    padding: 20px;
                }
            }
        }
    }
    .subject{
        width: 1156px;
        .screen{
            .condition_item{
                display: flex;
                >span{
                    margin-right: 38px;
                    color:#333333;
                }

                .active{
                    color: #FED54C;
                }
                .position{
                    display: flex;
                    margin-bottom: 30px;
                    >div{
                        display: flex;
                        align-items: center;
                        margin-right: 30px;
                        >span{
                            margin-right: 7px;
                            cursor: pointer;
                        }
                        >img{
                            cursor: pointer;
                            transform: rotate(180deg);
                            transition: transform .3s;
                        }
                    }
                    .position_active{
                        >span{
                            color: #FED54C;
                        }
                        >img{
                            transform: rotate(0deg);
                        }
                    }

                }

                .option_item{
                    display: flex;
                    margin-bottom: 30px;
                    font-size: 14px;
                    flex-wrap: wrap;
                    width: 1050px;
                    >span{
                        margin-right: 25px;
                        cursor: pointer;
                        margin-bottom: 10px;
                    }

                    .zj_css{
                        display: flex;
                        align-items: center;
                        .separator{
                            width: 8px;
                            height: 1px;
                            background: #999999;
                            margin: 0 8px;
                        }
                        .el-input{
                            width: 70px;
                            .el-input__inner{
                                height: 32px;
                                line-height: 32px;
                            }
                        }
                    }


                }
            }
            .screen_select{
                display: flex;
                >span{
                    margin-right: 20px;
                    font-size: 14px;
                    color:#333333;
                }
                >div{
                    display: flex;
                    align-items: center;
                    .tags{
                        display: flex;
                        align-items: center;
                        padding: 2px 7px;
                        background: #FFFAE9;
                        border: 1px solid #FED54C;
                        border-radius: 3px;
                        margin-right: 12px;
                        >span{
                            font-size: 14px;
                            color: #FED54C;
                            margin-right: 5px;
                        }
                        >img{
                            cursor: pointer;
                        }
                    }
                }
            }

            .list_sort{
                display: flex;
                align-items: center;
                cursor: pointer;
                >span{
                    margin-right: 30px;
                    color: #666666;
                }
                .sort_icon{
                    position: relative;
                }
                .sort_icon:after{
                    position: absolute;
                    content: "";
                    top: 4px;
                    bottom: 0;
                    right: -19px;
                    width: 20px;
                    height: 14px;
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAbdJREFUaAXtmAlPwzAMhcclxo0mrv//6xA3A4S4eR8ikpFomqbtkkm25NVZW/e9F7d1Opm4uQKugCvgCrgCS6zAyoDYybX9m+9pwLzRVOvRvek7AX8kDwQ4s4nEofYdcIDsXn73E2X+DEFgVdcG/JbBsGHiUcO+BAB/It8cFWUkeR8Ca8p7Kl+Y2v/xQMEcg/iZ3IJ/z0nU95wcAoAGvJ29a42bbtq+GKPndyVArQOe8gl2qeAxDBa9tSq2XXuqA7hhw7vjSzHgn+XFLJUAz/djg/JT8YX8xfxXJEwlsGvQfSgG/Kv5r1iYeg9cCSFq86Q5l1cBXjj+PEkYN1koGeqfGajGUksIwJCozlJLqDrgAZATCEqU2voMjKA8ooa3fWv62mYA4LQreBK2pINaZRjmAMCzsqNhpO+iaWydiZoIsFa2a2o6XBrGqNVCYE8o9w3SB8VzM24MayDAx4CZQUh7fmvG0bBLKxFNlLmT1Z1V/k1jGsfW0gnXK03A1nxo0zv1XDWUEGKiOGuMzh8GaiHA0jRrjVEDgRuBz15XlyDAjRqMxyW+dLYjxLibK+AKuAKugCvQS4FvH2YxiGAn5MYAAAAASUVORK5CYII=) no-repeat 50% scroll;
                    background-size: 100%;
                }
                .down:after{
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAkdJREFUaAXtl79v00AUx98zKaQ/EAz8mppslfgTOoHUpYKxqZiQUIREpA5dmQgDI0uRmiwIulbdWiQ22rULUiQGJiqEEAIyAUobtX59j8TOVXHtq+PmLtKddPE7+/z8+X7v2bEBXHMOOAecA84B58AIO4AZskuuiW6+fxnmjU2Viz2qf1Dgr3EPBMiZkSKoVqwC+c9kAqD3HCt71f9xyp8sBHh8bYEfVxjGlPhcw0EFCPwN7pfOlTIm+SACLnDem9yH5naUDnEwTRPht7ir8IdpEg16ThoBAi3w6uo1eRx50w4KmHT+WQVIrQu8lE/QfnHwNxgMe6u6mHTtPE+QGzb47yCOBb7F3VjTFSDP9+sKpc/xT+4Hyj4joa6AKYXuiGOBbyv7jIW698BvJhS35Unzg7sV8Mxx4kki49NaUDJS/7IC1jTdEhJgEWFd0y0h68ADICcgcMLU1q1A1s7Tm+JVWi+pryqxl7BqBRg+Dy16B83dTXo9czmWvHvQGgFEhLDvrwHQLBDNQ3t/hz7cSXzMJ07QcSGTObXCC86z2MtFb/HuduI3hhUrQPVCmcGf9uDxFVa+rvTGp0fGBdDq9BwQ1EJExC2oPFoOxwmBWQHk3wbEDa75zqcp4keYvPIAsar92mL6HigxfMdjhG9wMX8fHzbO9GlqWkAXHv9AzruH5c/fEyqm77DZEhIc5NdzxEV8/KXRR6exwwIB3hI+2XuvwRo5ZfgCyP8UkiC+ZPh6OB6VgOrTC9JHhddxOgecA84B54C9DhwDatxqX7nYEdEAAAAASUVORK5CYII=) no-repeat 50% scroll;
                    background-size: 100%;
                }
                .up:after{
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAlxJREFUaAXtV79PFEEUfu8OAicRiAFCIwFDDLEzViYQTUgoIJZIYoeFcv8QiIXBxAoLGwttsIDY0QoFyR0mNMoP+RERj7vn947bcM3uzu3ewJjMS15udufNm+/75s3eDJE3r4BXwCvgFfAKeAWIRIRlYeipzA9MX6Ue3IzJ5MvjFtosvAWLZ9V8nJ3hfGE5JHc33nfV+g7x+yskzuh1i1FURJAsP8zRRuE91mDqMkzuXbbttlIRkHfDnbS38xEQx+zCDM+emIC8Ge6l49JnErofnt5+TybJFLJ4Z4D+lNZQ8xfgGTSIN5LkSjumYQKyODhC5fM11Pzd6uRMZaLMc2IO27RpMUaOb4gAPpEPqFJZRcbbF1n5DMpPc764FDmLxU5jAvJ68BHArqBYemp4TohpkvPbHyzii01tREBeDT2hsnxC2XTWMu4RZ8cBfiV2BssBZl8hqbzAhm2vYmHagfITPFf4ZhmbUXqjFaBMzwzK5ysybmHDjvLcdyfAK0MjAvxy/TfleIpas6PYsEUd6IqZlRDQ8mwx1ZnFFmGjFbA1eTPyegLNUDFNDr8CadQLGauiGl+0XFsBBd5XcyNsRkEhSjX7tYLXc1YbXP/1++GxK+ESAb0r34AHdoIG7hnR5gqBm4AZHBQV8TH8SBtx5gKBHEDeqgN6ivZB3XNk0/goEZkleWcrhtYrX8LzLjy2dIIpr5tAfc3jako/4JUAnMmvCyWkOFVxBX+uD42YKwR+AvTfRoAHsS4Q2AcY3biJ7DoI6EYNTD+X6v+ddQCxujevgFfAK+AV8AqkUuAfyIR1FWercckAAAAASUVORK5CYII=) no-repeat 50% scroll;
                    background-size: 100%;
                }
                .list_sort_active{
                    color: #FED54C;
                }
            }
        }
        .room_card_subject{
            margin-bottom: 20px;
            .room_card_list{
                display: flex;
                flex-wrap: wrap;
                .room_card_item{
                    width: 368px;
                    height: 450px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    border: 1px solid #EFEFEF;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    a{
                        .item_img{
                            height: 270px;
                            border-radius: 8px 8px 0px 0px;
                            overflow: hidden;
                            >img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        >div:nth-child(2){
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            padding: 15px 20px;
                            box-sizing: border-box;
                            >span:nth-child(1){
                                font-size: 18px;
                                font-weight: 600;
                                color: #333333;
                                margin-bottom: 10px;
                            }
                            >span:nth-child(2){
                                font-size: 14px;
                                color: #999999;
                                margin-bottom: 10px;
                            }
                            .item_tags{
                                display: flex;
                                margin-bottom: 10px;
                                >span{
                                    padding: 4px 8px;
                                    background: #F6F6F6;
                                    border-radius: 4px;
                                    font-size: 12px;
                                    color: #999999;
                                    margin-right: 10px;
                                }
                            }
                            >span:nth-child(4){
                                font-size: 20px;
                                color: #FF5717;
                            }
                        }
                    }
                }
                .room_card_item:hover{
                    border-color: #FED54C;
                    >div:nth-child(2){
                        >span:nth-child(1){
                            color: #FED54C;
                        }
                    }
                }
            }

            /*分页样式*/
            .table-bottom-page {
                padding: 14px 12px 30px 12px!important;
                display: flex!important;
                align-items: center!important;
                justify-content: right!important;

                .el-pagination.is-background .el-pager li:not(.disabled).active {
                    background-color: #FED54C!important;
                    color: #333333;
                }
                .el-pagination.is-background .el-pager li:not(.disabled):hover {
                    background-color: #eee;
                    color: #333333;
                }
            }
        }
    }


    .login{
        .el-dialog__body{
            padding: 30px 60px 10px 60px;
        }
        .login_input{
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid #E4E5E6;
            border-radius: 4px;
            margin-bottom: 16px;
            img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            .el-input__inner{
                height: 30px;
                line-height: 30px;
                border: none;
                border-left: 1px solid #E4E5E6;
                border-radius: 0;
            }
        }

        .dialog-footer{
            display: flex;
            flex-direction: column;
            padding-left: 40px;
            padding-right: 40px;
            button{
                margin: 0;
                width: 100%!important;
                height: 50px!important;
                background: #FFD542;
                border-radius: 4px;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
    .register{
        .register_input{
            .el-input__inner{
                height: 50px;
                line-height: 50px;
                margin-bottom: 16px;
            }
        }
        .yzm{
            display: flex;
            justify-content: space-between;
            .el-input{
                width: 268px;
            }
            .el-button{
                height: 50px;
                background-color: #FFD542;
                border-color: #FFD542;
            }
        }
  
    }

    .ql-editor {
        min-height: 500px !important;
    }



    .el-dialog{
        border-radius: 12px;
        .el-dialog__header{
            background: #ECEFF3;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .el-dialog__headerbtn{
                font-size: 20px;
            }
        }
    }
    .el-dialog__header{
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        background: #fff!important;
    }

    .el-dialog__footer{
        text-align: center;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        .dialog-footer{
            .el-button{
                width: 100px;
                height: 35px;
                padding: 0;
            }
            .btn_close{
                background-color: #fff;
                border-color: #e8e8e8;
                color: #333333;
            }
            .btn_submit{
                background-color: #FFD542!important;
                border-color: #FFD542!important;
                color: #333333!important;
            }
        }
    }


}
</style>
