<template>
    <el-main class="search" v-loading="loading">
        <div class="top_banner">
            <div class="navigation_bar">
                <div>
                    <img src="../assets/img/logo_mz2.png" alt="" style="width:302px;height:34px;">
                    <div style="display: flex;align-items: center;">
                        <el-input
                            placeholder="请输入区域、小区名"
                            prefix-icon="el-icon-search"
                            @keyup.enter.native="search_gjz"
                            v-model="search"
                            style="width:200px;">
                        </el-input>
                        <ul>
                            <li v-for="(item,index) in nav_top" :key="index">
                                <router-link v-if="item.name!=='发布'" :to="{path:item.url}" :class="nav_top_active==index?'nav_active':''" style="text-decoration: none;outline: none;">{{item.name}}</router-link>
                                <div v-else :class="nav_top_active==index?'nav_active':''" @click="rich_text_show" class="fbfy fbfy_after" style="text-decoration: none;outline: none;">{{item.name}}</div>
                            </li>
                            <li class="login_css">
                                <img src="../assets/img/tx@2x_black.png" alt="" style="width:22px;height:22px;">
                                <span v-if="!username" @click="login_show">登录/注册</span>
                                <div v-else>
                                    <span style="margin-right:10px;">{{username}}</span>
                                    <span @click="exit">退出</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="subject">
            <el-empty description="暂无内容"></el-empty>

        </div>

        <company-information></company-information>
        
        <el-dialog
        :title="dialog_title"
        custom-class="login register"
        :visible.sync="login_dialog"
        width="520px"
        top="20vh"
        :close-on-click-modal="false"
        :before-close="lr_close">
            <div v-show="dialog_title=='登录'">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/sjh@2x.png" alt="">
                            <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="login_input">
                            <img src="../assets/img/mm@2x.png" alt="">
                            <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div v-show="dialog_title=='注册'" class="register_input">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-input v-model="tel" placeholder="请输入手机号"></el-input>
                    </el-col>
                    <el-col :span="24" class="yzm">
                        <el-input v-model="code" placeholder="请输入验证码"></el-input>
                        <el-button @click="obtain_code" type="primary" :disabled="code_disabled">{{code_text}}</el-button>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="passwrod" placeholder="请输入密码" type="password"></el-input>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-model="repassword" placeholder="请再次输入密码" type="password"></el-input>
                    </el-col>
                </el-row>
                <el-checkbox v-model="agreement_val">
                    <router-link :to="{path:'/agreement'}" target="_blank" style="text-decoration: none;outline: none;color: #333;">
                    我接受用户协议和隐私政策
                    </router-link>
                </el-checkbox>
            </div>

            <span v-show="dialog_title=='登录'" slot="footer" class="dialog-footer">
                <el-button @click="login_submit" type="primary" class="btn_submit">登 录</el-button>
                <el-button @click="login_handleClose" class="btn_close">注 册</el-button>
            </span>

            <span v-show="dialog_title=='注册'" slot="footer" class="dialog-footer">
                <el-button @click="register_submit" :disabled="!agreement_val" type="primary" class="btn_submit">注 册</el-button>
                <el-button @click="register_handleClose" class="btn_close">返回登录</el-button>
            </span>
        </el-dialog>

        <el-dialog
        title="发布房源"
        custom-class="rich_text"
        :visible.sync="rich_text_dialog"
        width="1000px"
        top="5vh"
        :close-on-click-modal="false"
        :before-close="rich_text_handleClose">

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"> 
                <el-form-item label="房源标题：" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
                <!-- 富文本编辑器 -->
                <el-form-item label="房源内容：" prop="content">
                    <!-- 失去焦点时手动校验该字段 (当前 content 属性值) -->
                    <el-upload class="avatar-uploader" :action="uploadUrl" name="file" :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeUpload" :multiple="true" style="display:none;"></el-upload>
                        <quill-editor
                            ref="QuillEditor"
                            :options="editorOption"
                            @blur="$refs.ruleForm.validateField('content')"
                            v-model="ruleForm.content"/>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="rich_text_handleClose" class="btn_close">取 消</el-button>
                <el-button @click="rich_text_submit" type="primary" class="btn_submit">发 布</el-button>
            </span>
        </el-dialog>


    </el-main>
</template>
<script>
import { post,getToken ,setToken } from "@/api/request";
import companyInformation from '../components/companyInformation.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // 加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'],                      //引用，代码块
  [{ 'header': 1 }, { 'header': 2 }],               // 几级标题
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],     // 有序列表，无序列表
  [{ 'script': 'sub' }, { 'script': 'super' }],      // 下角标，上角标
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // 缩进
  [{ 'direction': 'rtl' }],                         // 文字输入方向
  [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],// 标题
  [{ 'color': [] }, { 'background': [] }],          // 颜色选择
  [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],// 字体
  [{ 'align': [] }], // 居中
  ['clean'],                                       // 清除样式,
  ['link', 'image'],  // 上传图片、上传视频
]

export default {
    name:'search',
    components:{
        companyInformation,
    },
    data(){
        return{
            search:'',
            loading:false,
            nav_top:[
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],
            nav_top_active:5,


            username:'',
            dialog_title:'登录',
            login_dialog:false,
            tel:'',
            code:'',
            passwrod:'',
            repassword:'',

            code_disabled:false,
            code_text:'获取验证码',


            rich_text_dialog:false,
            ruleForm: {
                content: '', 
                title: '', 
            },
            rules: {
                content: [
                    { required: true, message: '请输入文本', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ],
            },


            page:1,
            list_data:[],



            editorOption: {
                placeholder: '请在这里输入',
                theme: 'snow', //主题 snow/bubble
                modules: {
                history: {
                    delay: 1000,
                    maxStack: 50,
                    userOnly: false
                },
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                    image: function (value) {
                        if (value) {
                        // 调用element的图片上传组件
                        document.querySelector('.avatar-uploader input').click()
                        } else {
                        this.quill.format('image', false)
                        }
                    }
                    }
                }
                }
            },

            uploadUrl:'https://wxapp.manzu365.com/api/com/upload',


            agreement_val:false,


        }
    },
    created(){
        if(getToken()){
            this.username = localStorage.getItem("username");
        }
    },
    methods:{
        search_gjz(){
            post('index/icp/search', {key:this.search}).then(res => {
                if (res.err == 0) {
                    console.log(res);
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        beforeUpload (file) { },
        uploadSuccess (res) {
            // 获取富文本组件实例
            let quill = this.$refs.QuillEditor.quill
            // 如果上传成功
            console.log(res);
            if (res.err === 0 && res.data.url) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', res.data.url)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败！')
            }
        },

        // 发布房源
        rich_text_show(){
            let token = getToken();
            if(!token){
                this.$message.error('请先登录!');
                return;
            }
            this.rich_text_dialog = true;
        },
        rich_text_handleClose(){
            this.rich_text_dialog = false;
            this.$refs.ruleForm.resetFields();
        },
        rich_text_submit(){
            let token = getToken();
            post('index/icp/fb', {
                ...this.ruleForm,
                token:token
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发布成功!',
                        type: 'success'
                    });
                    this.rich_text_dialog = false;
                    this.$refs.ruleForm.resetFields();
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        

        login_show(){
            this.login_dialog = true;
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
        },
        login_handleClose(){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '注册';
        },
        login_submit(){
            this.loading = true;
            post('index/icp/login', {
                tel:this.tel,
                passwrod:this.passwrod
            }).then(res => {
                if (res.err == 0) {
                    this.username = res.data.username;
                    this.nav_top.push();
                    setToken(res.data.token);
                    localStorage.setItem('username', res.data.username);
                    this.login_dialog = false;
                }
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        register_handleClose(){
            this.dialog_title = '登录';
        },
        obtain_code(){
            post('index/icp/sendcode', {
                tel:this.tel,
            }).then(res => {
                if (res.err == 0) {
                    this.$message({
                        message: '发送成功!',
                        type: 'success'
                    });
                    this.code_disabled = true;
                    let num = 60;
                    this.timer = setInterval(()=>{
                        num--;
                        if(num > 0){
                            this.code_text = num+'秒';
                            this.$apply();
                        }else{
                            clearInterval(this.timer); //清除js定时器
                            this.code_text = '发送验证码';
                            this.code_disabled = false;
                            this.$apply();
                        }
                    },1000)
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },
        register_submit(){
            post('index/icp/reg', {
                tel:this.tel,
                code:this.code,
                passwrod:this.passwrod,
                repassword:this.repassword,
            }).then(res => {
                if (res.err == 0) {
                    this.tel = '';
                    this.code = '';
                    this.passwrod = '';
                    this.repassword = '';
                    this.dialog_title = '登录';
                }
            }).catch(error => {
                if(error.response.status!=200){
                    this.$alert("网络错误", {
                        type: "error",
                    });
                }
            })
        },

        lr_close(done){
            this.tel = '';
            this.code = '';
            this.passwrod = '';
            this.repassword = '';
            this.dialog_title = '登录';
            done();
        },
        exit(){
            this.nav_top = [
                {name:'首页',url:'/'},
                {name:'审核',url:'examine_list'},
                {name:'发布',url:''}
            ],
            this.username = '';
            window.localStorage.clear();
            // localstore.removeSession(youyou_token);
            window.location.reload();
        },


    }
}
</script>
<style lang="scss" scoped>
::v-deep.search{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    .top_banner{
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        .navigation_bar{
            position: fixed;
            top: 0;
            width: 1920px;
            height: 64px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            z-index: 99;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
            >div{
                width: 1156px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-input__inner{
                    height: 32px;
                    line-height: 32px;
                    border-radius: 16px;
                    background: #F0F0F0;
                }
                .el-input__icon{
                    line-height: 32px;
                }
                ul{
                    display: flex;
                    li{ 
                        padding:0;
                        margin:0;
                        list-style:none;
                        a{
                            margin-right: 20px;
                            font-size: 16px;
                            padding: 9px 0;
                            color: #666666;
                            position: relative;
                        }
                        .fbfy{
                            margin-right: 20px;
                            font-size: 16px;
                            color: #666666;
                            position: relative;
                            cursor: pointer;
                        }
                        a:hover:after,
                        .fbfy:hover:after, {
                            width: 120%;
                        }
                        a:after,
                        .fbfy:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            width: 0;
                            height: 2px;
                            background: #FFD542;
                            border-radius: 1px;
                            -webkit-transition: width .3s;
                            transition: width .3s;
                            will-change: width;
                        }
                        .nav_active{
                            position: relative;
                            color: #333;
                        }
                        .nav_active:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            width: 100%;
                            height: 2px;
                            background: #FFD542;
                            border-radius: 1px;
                        }

                        .fbfy_after:after{
                            bottom: -9px;
                        }
                    }
                }
            }

            .login_css{
                display: flex;
                color: #666;
                img{
                    margin-right: 8px;
                }
                span{
                    cursor: pointer;
                }
            }
        }
    }

    .subject{
        width: 1156px;
        height: 720px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 20px;
    }

    .login{
        .el-dialog__body{
            padding: 30px 60px 10px 60px;
        }
        .login_input{
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid #E4E5E6;
            border-radius: 4px;
            margin-bottom: 16px;
            img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            .el-input__inner{
                height: 30px;
                line-height: 30px;
                border: none;
                border-left: 1px solid #E4E5E6;
                border-radius: 0;
            }
        }

        .dialog-footer{
            display: flex;
            flex-direction: column;
            padding-left: 40px;
            padding-right: 40px;
            button{
                margin: 0;
                width: 100%!important;
                height: 50px!important;
                background: #FFD542;
                border-radius: 4px;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
    .register{
        .register_input{
            .el-input__inner{
                height: 50px;
                line-height: 50px;
                margin-bottom: 16px;
            }
        }
        .yzm{
            display: flex;
            justify-content: space-between;
            .el-input{
                width: 268px;
            }
            .el-button{
                height: 50px;
                background-color: #FFD542;
                border-color: #FFD542;
            }
        }
  
    }

    .ql-editor {
        min-height: 500px !important;
    }
    

    .el-dialog{
        border-radius: 12px;
        .el-dialog__header{
            background: #ECEFF3;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .el-dialog__headerbtn{
                font-size: 20px;
            }
        }
    }
    .el-dialog__header{
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        background: #fff!important;
    }

    .el-dialog__footer{
        text-align: center;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        .dialog-footer{
            .el-button{
                width: 100px;
                height: 35px;
                padding: 0;
            }
            .btn_close{
                background-color: #fff;
                border-color: #e8e8e8;
                color: #333333;
            }
            .btn_submit{
                background-color: #FFD542!important;
                border-color: #FFD542!important;
                color: #333333!important;
            }
        }
    }


}
</style>

