import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue";
import room_details from "../views/room_details.vue";
import examine_list from "../views/examine_list.vue";
import search from "../views/search.vue";
import agreement from "../views/agreement.vue";


Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "index",
    component: index,
}, {
    path: "/room_details",
    name: "room_details",
    component: room_details,
}, {
    path: "/examine_list",
    name: "examine_list",
    component: examine_list,
}, {
    path: "/search",
    name: "search",
    component: search,
}, {
    path: "/agreement",
    name: "agreement",
    component: agreement,
}, ];

const router = new VueRouter({
    // mode: "history",
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;