<template>
    <div class="companyInformation">
        <div>
            <img src="../assets/img/logo_mz2.png" alt="" style="width:302px;height:34px;margin-right:20px;">
            <div>
                <div>广州满租科技发展有限公司 manzu365.cn 版权所有</div>
                <a href="https://beian.miit.gov.cn/?_t_=1594883465137#/Integrated/index" target="_blank" style="text-decoration: none;color: #999;">
                    粤ICP备15103956号
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'companyInformation',
    data(){
        return{
            
        }
    },
}
</script>
<style lang="scss" scoped>
.companyInformation{
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(0,0,0,.12);
    >div{
        width: 1156px;
        padding: 20px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
            font-size: 14px;
            color: #999;
        }
    }
}
</style>
