import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import BaiduMap from 'vue-baidu-map';

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

Vue.use(ElementUI);

Vue.use(BaiduMap, { ak: '3YFKilXhf8yswlPgRi7iiQ6UVQ6zglc9' });

Vue.use(VueQuillEditor);


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");