/**
 * 加法函数，用来得到精确的加法结果
 * @param left_operand
 * @param right_operand
 * @param scale
 * @returns {string}
 */
export const bcadd = (left_operand, right_operand = 0, scale = 2) => {
  const reg = new RegExp('^\\d+(?:\\.\\d{0,' + scale + '})?')
  const r1 = deal(left_operand)
  const r2 = deal(right_operand)
  const m = Math.pow(10, Math.max(r1, r2))
  return returnFloat(((left_operand * m + right_operand * m) / m).toString().match(reg)[0], scale)
}

/**
 * 减法函数，用来得到精确的减法结果
 * @param left_operand
 * @param right_operand
 * @param scale
 * @returns {string}
 */
export const bcsub = (left_operand, right_operand, scale = 2) => {
  const reg = new RegExp('^\\d+(?:\\.\\d{0,' + scale + '})?')
  const r1 = deal(left_operand)
  const r2 = deal(right_operand)
  const m = Math.pow(10, Math.max(r1, r2))
  return returnFloat(((left_operand * m - right_operand * m) / m).toString().match(reg)[0], scale)
}

/**
 * 乘法函数，用来得到精确的乘法结果
 * @param left_operand
 * @param right_operand
 * @param scale
 * @returns {string}
 */
export const bcmul = (left_operand, right_operand, scale = 2) => {
  const reg = new RegExp('^\\d+(?:\\.\\d{0,' + scale + '})?')
  let m = 0
  m += deal(left_operand)
  m += deal(right_operand)
  const r1 = Number(left_operand.toString().replace('.', ''))
  const r2 = Number(right_operand.toString().replace('.', ''))
  return returnFloat(((r1 * r2) / Math.pow(10, m)).toString().match(reg)[0], scale)
}

/**
 * 除法函数，用来得到精确的除法结果
 * @param left_operand
 * @param right_operand
 * @param scale
 * @returns {string}
 */
export const bcdiv = (left_operand, right_operand, scale = 2) => {
  const reg = new RegExp('^\\d+(?:\\.\\d{0,' + scale + '})?')
  const t1 = deal(left_operand)
  const t2 = deal(right_operand)
  const r1 = Number(left_operand.toString().replace('.', ''))
  const r2 = Number(right_operand.toString().replace('.', ''))
  return returnFloat(((r1 / r2) * Math.pow(10, t2 - t1)).toString().match(reg)[0], scale)
}

/**
 * 判断是否为数字
 * @param value
 * @param int
 * @returns {boolean}
 */
export const isNumber = (value, int = false) => {
  const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
  if (!int) {
    return regPos.test(value) || regNeg.test(value)
  } else {
    return regPos.test(value)
  }
}

/**
 * 求小数点后的数据长度
 * @param arg
 * @returns {number}
 */
function deal(arg) {
  let t = 0
  try {
    t = arg.toString().split('.')[1].length
    // eslint-disable-next-line no-empty
  } catch (e) { }
  return t
}

/**
 * 小数补零
 * @param string
 * @param scale
 * @returns {*}
 */
function returnFloat(string, scale){
  let s = string.toString().split(".");
  if (s.length === 1) {
    s.push('0')
    if (scale > 0) {
      string = string.toString() + '.0'
    }
  }
  if (scale <= 0) {
    string = string.replace('.', '')
  }
  for (let i = scale; i > s[1].length; i--) {
    string = string.toString() + '0'
  }
  return string
}
